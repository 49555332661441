import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageTemplate, Header } from "components";
import { PageView } from "services/google";
import { CreatePlanStepper as Stepper, CreatePlanSteps as Steps, CreatePlanFooter as Footer } from "components";
import { Formik } from "formik";
import { Form } from "formik-antd";
import initialValues from "./utils/initialValues";
// import validationSchema from "./utils/validationSchema";
import { withFormikDevtools } from "formik-devtools-extension";
import { message } from "antd";
import { getToken, setToken, getUserId } from "services/utils";
import { createPlans, attachPlanInfoToReport, getRole, isProfessional } from "services/api/admin.api";
import { updateGroup, checkEmployeesWithMissingPremiumTier } from "services/api/groups.api";
import { formatPlans } from "./utils/format";
import { loadValues } from "./utils/loadValues";
import { loadInsuranceNetworks } from "./utils/loadInsuranceNetworks";
import { gtag, install } from "ga-gtag";
import { validationSchema } from "./utils/validationSchema";
import FormikPersist from "./utils/formikPersist";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch } from "react-redux";
import Actions from "store/auth/actions";
import { deleteData } from "indexedDB";

const CreatePlanPage = () => {
  const history = useHistory();
  const { state } = useLocation();
  const dispatch = useDispatch();

  const [visitedSteps, setVisitedSteps] = useState([]);
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [advancedTabActive, setAdvancedTabActive] = useState(false);
  const [finishedGetRoleCall, setFinishedGetRoleCall] = useState(false);
  const [isAllowed, setIsAllowed] = useState(false);

  const currentSelector = useSelector((state) => state.plan.current);
  const current = window.location.pathname.includes("create-plan") ? currentSelector : currentSelector + 1;
  const username = useSelector((state) => state.profile.username);
  //report to add partner plans
  const reportId = state?.reportId;
  const reportToVerify = state?.reportToVerify;
  const displayName = state?.displayName;
  const returnToDetailsPage = state?.returnToDetailsPage;
  const activeTab = returnToDetailsPage && state?.activeTab;
  const option = state?.option
    ? state.option === "partner" || state.option === "jobOffer"
      ? "partner"
      : state.option
    : "";
  const group = state?.group;
  const premiumTier = state?.premiumTier;
  const waiverTier = state?.waiverTier;
  const customReportId = state?.customReportId;
  const groupObj = state?.groupObj;
  const year = state?.year;

  const networksGridId = reportId || customReportId;

  let token = {};

  const getValuesFromPlans = async (reportId, option) => {
    token = await getToken();
    const values = await loadValues({
      reportId,
      token,
      option,
      reportToVerify,
      startingPremiumTier: premiumTier,
      startingWaiverTier: waiverTier,
      displayName,
    });
    const insuranceNetworkValues = await loadInsuranceNetworks(networksGridId);
    setFormikInitialValues({
      ...values,
      ...insuranceNetworkValues,
    });
  };

  const addValuesFromNetworksGrid = async (networksGridId, displayName) => {
    const insuranceNetworkValues = await loadInsuranceNetworks(networksGridId);
    setFormikInitialValues({
      ...formikInitialValues,
      ...insuranceNetworkValues,
      group: !formikInitialValues.group || formikInitialValues.group === "" ? displayName : formikInitialValues.group,
    });
  };

  useEffect(() => {
    const trackingID = process.env.REACT_APP_GOOGLE_MANAGER_ID;
    install(trackingID);
    gtag("event", "create_plan_step", { step: 1 });
    PageView();
    (reportId || reportToVerify) && !localStorage.getItem("form") && getValuesFromPlans(reportId, option);
    if (networksGridId && displayName) {
      addValuesFromNetworksGrid(networksGridId, displayName);
    }
    (async () => {
      const token = await getToken();
      const isAdminResponse = await getRole(token);
      const isProfessionalResponse = await isProfessional(token);
      setIsAllowed(isProfessionalResponse.status === 200 || isAdminResponse.status === 200);
      setFinishedGetRoleCall(true);
    })();
  }, []);

  if ((state == null || Object.keys(state)?.length === 0) && finishedGetRoleCall && !isAllowed) {
    window.location.href = "https://predictabill.com";
    return null;
  }

  const partnerJustForComparison = state?.partnerJustForComparison
    ? state?.partnerJustForComparison
    : state?.option
    ? state.option === "jobOffer"
      ? "jobOffer"
      : ""
    : "";

  const values = (localStorage.getItem("form") && JSON.parse(localStorage.getItem("form"))) || [];
  const fullValidationSchema = validationSchema(values);
  const validationSchemaWithoutAdvanced = [...validationSchema(values)];
  validationSchemaWithoutAdvanced.splice(5, 1);

  const userTypeformResponse =
    (localStorage.getItem("userTypeformResponse") && JSON.parse(localStorage.getItem("userTypeformResponse"))) || {};
  const pregnancyTypeformResponse =
    (localStorage.getItem("pregnancyTypeformResponse") &&
      JSON.parse(localStorage.getItem("pregnancyTypeformResponse"))) ||
    {};
  const currentReport = state?.currentReport;

  return (
    <PageTemplate isContentHover padding="0 12.5%" header={<Header V2 />}>
      <Formik
        initialValues={formikInitialValues}
        enableReinitialize={true}
        validateOnMount={true}
        // isInitialValid={false}
        validationSchema={advancedTabActive ? fullValidationSchema[current] : validationSchemaWithoutAdvanced[current]}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const userId = await getUserId();
            const plansValues = await formatPlans(values, year);
            const oldPlansIds = values.table ? values.table.map((tableValue) => tableValue.id) : [];
            const formValues = JSON.stringify(values);
            const isPartnerPlans = values.isPartnerPlans || option === "partner";
            const createPlansResponse = await createPlans(false, token, plansValues, true);
            const planIds = createPlansResponse.data.plans;
            const response = await attachPlanInfoToReport(
              reportId,
              token,
              userId,
              planIds,
              isPartnerPlans,
              formValues,
              plansValues,
              partnerJustForComparison,
              userTypeformResponse,
              pregnancyTypeformResponse,
              currentReport,
              oldPlansIds,
              group,
              customReportId,
              groupObj,
            );
            setSubmitting(false);
            if (response.status === 200) {
              message.success({
                content: <>{"Plans Created Successfully"}</>,
                icon: (
                  <span
                    role="img"
                    aria-label="check-circle"
                    className="anticon anticon-check-circle"
                    style={{
                      color: "#00a19b",
                    }}
                  >
                    <svg
                      viewBox="64 64 896 896"
                      focusable="false"
                      data-icon="check-circle"
                      width="1em"
                      height="1em"
                      fill="currentColor"
                      aria-hidden="true"
                    >
                      <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
                    </svg>
                  </span>
                ),
              });
              localStorage.removeItem("userTypeformResponse");
              localStorage.removeItem("pregnancyTypeformResponse");
              localStorage.removeItem("form");
              deleteData(reportId);
              if (groupObj) {
                updateGroup({ originalName: groupObj.name, form: { name: groupObj.name, displayName: values.group } });
                if (year) {
                  const missingEmployeesResult = await checkEmployeesWithMissingPremiumTier({
                    name: groupObj.name,
                    year,
                    reportId,
                    token,
                  });
                  const { wrongRoles } = missingEmployeesResult.data;
                  if (wrongRoles.length > 0) {
                    history.push(`/my-plans/${groupObj.name}/${reportId}`);
                    return;
                  }
                }
              }
              if (response.data.authenticatedUser) {
                const { username, token } = response.data.authenticatedUser;
                setToken(token);
                dispatch(Actions.Creators.loginSuccess(username, token));
              }
              if (reportId) {
                history.push(`/optimizeV2/${reportId}`, {
                  activeTab: activeTab || 1,
                });
              } else {
                history.push(`/optimizeV2/${response.data.reportId}`, {
                  activeTab: activeTab || 1,
                });
              }
            } else message.error("Fail!");
          } catch (e) {
            console.log(e);
          }
        }}
      >
        {(formikProps) => {
          withFormikDevtools(formikProps);
          return (
            finishedGetRoleCall && (
              <Form>
                <Stepper
                  validateForm={formikProps.validateForm}
                  visitedSteps={visitedSteps}
                  setVisitedSteps={setVisitedSteps}
                />
                <Steps setAdvancedTabActive={setAdvancedTabActive} />
                <Footer
                  isSubmitting={formikProps.isSubmitting}
                  visitedSteps={visitedSteps}
                  setVisitedSteps={setVisitedSteps}
                />
                <FormikPersist name="form" />
              </Form>
            )
          );
        }}
      </Formik>
    </PageTemplate>
  );
};

export default CreatePlanPage;
