// Updates from V2 to V3
// expected/unexpected cost

import React, { useState, useEffect } from "react";
import styled, { css } from "styled-components";
import { Collapse } from "react-collapse";
import { Icon as AntDIcon } from "@ant-design/compatible";
import { MinusOutlined } from "@ant-design/icons";
import { CalculateHeader as Header, Currency, Icon, Link, Text, TableCell } from "components";
import CustomSelect from "components/molecules/Select";
import { useDispatch, useSelector } from "react-redux";
import { isEmpty } from "utils/object";
import { Badge, Popconfirm } from "antd";
import { getToken } from "services/utils";
import { disablePreference } from "services/api";
import Actions from "store/report/actions";
import PlanActions from "store/plan/actions";
import OptimizeModal from "../OptimizeModalV3";
import { formatCurrency } from "utils/currency";
import { useHistory } from "react-router-dom";
import FilterSortButton from "components/molecules/FilterSortButton";

const Wrapper = styled.div`
  display: flex;
  margin: 10px auto;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 82%;
  transition: left 0.4s ease-in-out;
  position: relative;
  left: 0;

  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
  }
`;

const FlexWrapper = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "space-evenly")};
  width: 100%;
  overflow-x: scroll;
`;

const CardsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  padding-bottom: 20px !important;
`;

const Tag = styled.span`
  display: flex;
  width: 8vw;
  height: 30px;
  justify-content: center;
  align-items: center;
  background-color: #21355b;
  margin-bottom: 10px;
  border-radius: 10px;
`;

const InnerWrapper = styled.div`
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: column;
  align-items: ${(props) => (props.align ? `flex-${props.align}` : "center")};
  justify-content: start;
  ${(props) => props.width && `width: ${props.width};`};

  .ReactCollapse--collapse {
    width: 100%;
  }

  > .ReactCollapse--collapse {
    transition: height 500ms;

    > * {
      transition: height 500ms;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }
`;

const Row = styled.tr`
  height: ${(props) => {
    if (props.selectHeader) {
      return "150px";
    } else if (props.labelsHeader) {
      return "100px";
    } else if (props.header) {
      return "81px";
    } else if (props.category) {
      return "41px";
    } else if (props.subCategory) {
      return "41px";
    } else {
      return "70px";
    }
  }};

  ${(props) => props.customHeight && `height: ${props.customHeight};`}
  ${(props) => props.customWidth && `width: ${props.customWidth};`}
  background-color: transparent;
  padding: ${(props) => (props.padding ? props.padding : "4px 5px;")};
  margin: ${(props) => (props.header ? "2px;" : "0;")};
  display: ${(props) => (props.hide ? "none" : "flex")};
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  cursor: ${(props) => (props.cursor ? props.cursor : "auto")};
  ${({ hover }) =>
    hover &&
    css`
      :hover {
        background-color: #04b8b1;
      }
    `}

  ${(props) => (props.bgColor ? `background-color: ${props.bgColor};` : "background-color: transparent;")}
`;

function colorFromLevel(level) {
  if (level === -1) return "#21355B"; // Default color

  const colors = ["#D74328", "#EC8916", "#F9A314", "#589B40", "#30706D", "#374F7F", "#4A377F"];
  const colorsAmount = colors.length;
  try {
    const levelInt = level ? parseInt(level) - 1 : 0;
    const colorToPick = levelInt % colorsAmount;

    return colors[colorToPick];
  } catch (error) {
    return colors[0];
  }
}

const CollapseSeparator = styled.div`
  border-left: 2px solid #000;
  margin-left: 6px;

  ${(props) => props.bColor && `border-color: ${props.bColor};`}
`;

function CustomCollapse({ children, isOpened, level }) {
  const selectedColor = colorFromLevel(level);

  return (
    <Collapse isOpened={isOpened}>
      <CollapseSeparator bColor={selectedColor}>{children}</CollapseSeparator>
    </Collapse>
  );
}

const Line = styled.div`
  border-bottom: ${(props) => (props.white ? "1px solid white" : "1px solid grey")};
  width: ${(props) => (props.category ? "164px" : props.subCategory ? "124px" : "288px")};
`;

const OptWrapper = styled.div`
  border: ${(props) => (props.custom ? "1px solid lightgrey" : "0px white")};
  border-radius: none;
  width: inherit;
  flex-flow: column;
  opacity: ${(props) => (props.opacity ? 0.4 : 1)};

  > .ReactCollapse--collapse {
    transition: height 500ms;
  }
`;

const IconWrapper = styled(AntDIcon)`
  display: flex !important;
  align-items: center;
  margin-left: 5px;
`;

const WhatIf = ({
  report,
  birthParentPlans,
  partnerPlans,
  updatePreference,
  allOptimizations,
  activeTab,
  customOptimizations,
  toggleSideBar,
  toggleAssumptions,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { preferences, birthParent, partner } = report;
  const optKeys = Object.keys(preferences).reverse();
  const secondaryOption = optKeys.find((_key) => preferences[_key].apply);

  const altBPCompany = birthParentPlans.length ? birthParentPlans[0].group : null;
  const altPartnerCompany = partnerPlans.length ? partnerPlans[0].group : null;

  const [options, setOptions] = useState({
    primary: "1",
    secondary: secondaryOption,
  });

  const [customPrefrenceModal, setCustomPrefrenceModal] = useState(false);

  const customPreferenceOne = useSelector((state) => state.report.customPreferenceOne);
  const customPreferenceTwo = useSelector((state) => state.report.customPreferenceTwo);

  useEffect(() => {
    const optKeys = Object.keys(preferences).reverse();
    const secondaryOption = optKeys.find((_key) => preferences[_key].apply);

    setOptions({ primary: "1", secondary: secondaryOption });
  }, [allOptimizations, preferences]);

  // expand rows
  const [expand, setExpand] = useState({
    total: false,
    Premium: false,
    "Premiums Before": false,
    "Premiums After": false,
    "Company Details A": false,
    "Company Details B": false,
    Surcharge: false,
    "Surcharges Before": false,
    "Surcharges After": false,
    Insurance: false,
    "Not Covered": false,
    "Insurance companyA": false,
    "Insurance companyB": false,
    "Out-Of-Network": false,
    "Reimbursement from Insurance": false,
    "Out-of-Pocket Accrual": false,
    "Opt-Out Payment": false,
    "Opt-Out Payment Before": false,
    "Opt-Out Payment After": false,
    "HSA Before": false,
    "HRA Before": false,
    leftover: false,
    "HSA After": false,
    "HRA After": false,
  });

  const [showRows, setShowRows] = useState({
    Premium: false,
    Surcharge: false,
    Insurance: false,
    "Not Covered": false,
    "Out-Of-Network": false,
    "Opt-Out Payment Before": false,
    "Opt-Out Payment After": false,
    "HSA Before": false,
    "HRA Before": false,
    "HSA After": false,
    "HRA After": false,
  });

  const [showSubRows, setShowSubRows] = useState({
    companyA: {
      name: birthParent.company,
      altName: altBPCompany,
      insuranceFields: {}, // Total, Copay, Coinsurance, Deductibles
      "Premiums Before": null,
      "Premiums After": null,
      "Surcharges Before": null,
      "Surcharges After": null,
      "Opt-Out Payment Before": null,
      "Opt-Out Payment After": null,
      "HSA Before": null,
      "HSA After": null,
      "HRA Before": null,
      "HRA After": null,
    },
    companyB: {
      name: partner.company,
      altName: altPartnerCompany,
      insuranceFields: {},
      "Premiums Before": null,
      "Premiums After": null,
      "Surcharges Before": null,
      "Surcharges After": null,
      "Opt-Out Payment Before": null,
      "Opt-Out Payment After": null,
      "HSA Before": null,
      "HSA After": null,
      "HRA Before": null,
      "HRA After": null,
    },
    "Out-Of-Network": {
      "Cost of Visits": null,
      "Reimbursement from Insurance": null,
      "Negotiated Rate": null,
      "Out-of-Pocket Accrual": null,
      Deductibles: null,
      Coinsurance: null,
    },
  });

  const handleExpand = (field) => {
    setExpand({ ...expand, [field]: !expand[field] });
  };

  const handleShow = (show, value) => {
    if (!showRows[show]) {
      setShowRows({ ...showRows, [show]: value ? value : !showRows[show] });
    }
  };

  const handleSubShow = (show, value, newFieldsA, newFieldsB) => {
    // show = companyA, companyB, or Out-Of-Network
    // value = showSubRows clone

    if (show === "companies") {
      const newCompanyA = newFieldsA ? Object.assign({}, showSubRows.companyA, newFieldsA) : showSubRows.companyA;
      const newCompanyB = newFieldsB ? Object.assign({}, showSubRows.companyB, newFieldsB) : showSubRows.companyB;
      setShowSubRows({
        ...showSubRows,
        companyA: newCompanyA,
        companyB: newCompanyB,
      });
    } else {
      setShowSubRows({ ...showSubRows, [show]: value });
    }
  };

  const getValue = (before, after, period) => {
    const returnNum = (num) => {
      if (typeof num === "number") return num;
      else return false;
    };

    const valueBefore = before ? returnNum(before.total) || before.total.value : 0;
    const valueAfter = after ? returnNum(after.total) || (after.total.value || 0) + (after.remaining || 0) : 0;
    const valueTotal = period === "Before" ? valueBefore : period === "After" ? valueAfter : valueBefore + valueAfter;

    return valueTotal;
  };

  // render sub row cost
  const renderSubCost = (
    before = [],
    after = [],
    unExpectedCost,
    expectedBefore = [],
    expectedAfter = [],
    expectedCost,
    period,
    type,
    totalElement,
  ) => {
    // idx 0 = unExpected, idx 1 = expected;
    let reRender = false;
    let items = {};
    // items = { keyword: [expectedCost, unExpectedCost] }
    // if (type === Insurance) items = { Company: {...keywords } }
    let maxCost = [unExpectedCost, expectedCost]; // use for when hsa/hra exceeds the amount used

    const getItems = (array, idx, period = "Before") => {
      array.forEach((item) => {
        if (type === "Premium") {
          const { title, value } = item;
          const company = title.replace(" Plan", "");
          items[`Premiums ${period}`] = items[`Premiums ${period}`] || {};

          items[`Premiums ${period}`].Total = items[`Premiums ${period}`].Total || [0, 0];
          items[`Premiums ${period}`].Total[idx] += value;

          items[`Premiums ${period}`][company] = items[`Premiums ${period}`][company] || [0, 0];
          items[`Premiums ${period}`][company][idx] += value;

          if (!items[`Premiums ${period}`].PremiumSubRows) {
            items[`Premiums ${period}`].PremiumSubRows = {};
          }

          if (!items[`Premiums ${period}`].PremiumSubRows[company]) {
            items[`Premiums ${period}`].PremiumSubRows[company] = {};
          }

          items[`Premiums ${period}`].PremiumSubRows[company].SpousalSurcharges = item.spousalSurcharge;
          items[`Premiums ${period}`].PremiumSubRows[company].Premium =
            items[`Premiums ${period}`][company][idx] - item.spousalSurcharge;
        } else if (type === "Surcharge") {
          const { title, value } = item;
          const company = title.replace(" Plan", "");
          items[`Surcharges ${period}`] = items[`Surcharges ${period}`] || {};

          items[`Surcharges ${period}`].Total = items[`Surcharges ${period}`].Total || [0, 0];
          items[`Surcharges ${period}`].Total[idx] += value;

          items[`Surcharges ${period}`][company] = items[`Surcharges ${period}`][company] || [0, 0];
          items[`Surcharges ${period}`][company][idx] += value;
        } else if (type === "Insurance") {
          const { company, value, deductible, coinsurance, copay } = item || {};

          items[company] = items[company] || {}; // items[Company] = {}
          items[company].Total = items[company].Total || [0, 0];
          items[company].Total[idx] += value;
          items[company]["Deductible"] = items[company]["Deductible"] || [0, 0]; // items[Company][Deductible] = []
          items[company]["Deductible"][idx] += deductible || 0;
          items[company]["Copay"] = items[company]["Copay"] || [0, 0];
          items[company]["Copay"][idx] += copay || 0;
          items[company]["Coinsurance"] = items[company]["Coinsurance"] || [0, 0];
          items[company]["Coinsurance"][idx] += coinsurance || 0;
        } else if (type === "Out-Of-Network") {
          const {
            costOfVisits,
            totalReimbursementFromInsurance,
            negotiatedRate,
            totalOOPAccrual,
            deductible,
            coinsurance,
          } = item || {};

          items["Cost of Visits"] = items["Cost of Visits"] || [0, 0];
          items["Reimbursement from Insurance"] = items["Reimbursement from Insurance"] || [0, 0];
          items["Negotiated Rate"] = items["Negotiated Rate"] || [0, 0];
          items["Out-of-Pocket Accrual"] = items["Out-of-Pocket Accrual"] || [0, 0];
          items["Deductibles"] = items["Deductibles"] || [0, 0];
          items["Coinsurance"] = items["Coinsurance"] || [0, 0];

          items["Cost of Visits"][idx] += costOfVisits || 0;
          items["Reimbursement from Insurance"][idx] += totalReimbursementFromInsurance || 0;
          items["Negotiated Rate"][idx] += negotiatedRate || 0;
          items["Out-of-Pocket Accrual"][idx] += totalOOPAccrual || 0;
          items["Deductibles"][idx] += deductible || 0;
          items["Coinsurance"][idx] += coinsurance || 0;
        } else if (type === "Opt-Out Payment") {
          const { title, value } = item;
          const company = title.replace(" Plan", "");
          items[`Opt-Out Payment ${period}`] = items[`Opt-Out Payment ${period}`] || {};

          items[`Opt-Out Payment ${period}`].Total = items[`Opt-Out Payment ${period}`].Total || [0, 0];
          items[`Opt-Out Payment ${period}`].Total[idx] += value;

          items[`Opt-Out Payment ${period}`][company] = items[`Opt-Out Payment ${period}`][company] || [0, 0];
          items[`Opt-Out Payment ${period}`][company][idx] += value;
        } else if (type === "Not Covered") {
          const { company, notCovered } = item || {};
          items["Not Covered"] = items["Not Covered"] || {};
          items["Not Covered"][company] = items["Not Covered"][company] || [0, 0];
          items["Not Covered"][company][idx] += notCovered;
        } else if (type === "HSA" || type === "HRA") {
          const { title, value } = item;

          const company = title.replace(" Contributions", "");
          items[company] = items[company] || [];
          items[company][idx] = (items[company][idx] || 0) + value;

          items[company][idx] = Math.min(maxCost[idx], items[company][idx]);
        }

        return;
      });
    };

    getItems(before, 0, "Before");
    getItems(after, 0, "After");
    getItems(expectedBefore, 1, "Before");
    getItems(expectedAfter, 1, "After");

    const showItems = Object.keys(items);
    const { companyA, companyB } = showSubRows;

    // sets showSubRows true for items with values
    if (type === "Premium") {
      let newFieldsA = {};
      let newFieldsB = {};
      if (companyA.name) {
        if (items["Premiums Before"]) {
          if (items["Premiums Before"][`${companyA.name}`] && !companyA["Premiums Before"]) {
            newFieldsA["Premiums Before"] = true;
            reRender = true;
          }
        }
        if (items["Premiums After"]) {
          if (items["Premiums After"][`${companyA.name}`] && !companyA["Premiums After"]) {
            newFieldsA["Premiums After"] = true;
            reRender = true;
          }
        }
      }

      if (companyB.name) {
        if (items["Premiums Before"]) {
          if (items["Premiums Before"][`${companyB.name}`] && !companyB["Premiums Before"]) {
            newFieldsB["Premiums Before"] = true;
            reRender = true;
          }
        }
        if (items["Premiums After"]) {
          if (items["Premiums After"][`${companyB.name}`] && !companyB["Premiums After"]) {
            newFieldsB["Premiums After"] = true;
            reRender = true;
          }
        }
      }

      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    } else if (type === "Surcharge") {
      let newFieldsA = {};
      let newFieldsB = {};
      if (companyA.name) {
        if (items["Surcharges Before"]) {
          if (items["Surcharges Before"][`${companyA.name}`] && !companyA["Surcharges Before"]) {
            newFieldsA["Surcharges Before"] = true;
            reRender = true;
          }
        }
        if (items["Surcharges After"]) {
          if (items["Surcharges After"][`${companyA.name}`] && !companyA["Surcharges After"]) {
            newFieldsA["Surcharges After"] = true;
            reRender = true;
          }
        }
      }

      if (companyB.name) {
        if (items["Surcharges Before"]) {
          if (items["Surcharges Before"][`${companyB.name}`] && !companyB["Surcharges Before"]) {
            newFieldsB["Surcharges Before"] = true;
            reRender = true;
          }
        }
        if (items["Surcharges After"]) {
          if (items["Surcharges After"][`${companyB.name}`] && !companyB["Surcharges After"]) {
            newFieldsB["Surcharges After"] = true;
            reRender = true;
          }
        }
      }

      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    } else if (type === "Opt-Out Payment") {
      let newFieldsA = {};
      let newFieldsB = {};
      if (companyA.name) {
        if (items["Opt-Out Payment Before"]) {
          if (items["Opt-Out Payment Before"][`${companyA.name}`] && !companyA["Opt-Out Payment Before"]) {
            newFieldsA["Opt-Out Payment Before"] = true;
            reRender = true;
          }
        }
        if (items["Opt-Out Payment After"]) {
          if (items["Opt-Out Payment After"][`${companyA.name}`] && !companyA["Opt-Out Payment After"]) {
            newFieldsA["Opt-Out Payment After"] = true;
            reRender = true;
          }
        }
      }

      if (companyB.name) {
        if (items["Opt-Out Payment Before"]) {
          if (items["Opt-Out Payment Before"][`${companyB.name}`] && !companyB["Opt-Out Payment Before"]) {
            newFieldsB["Opt-Out Payment Before"] = true;
            reRender = true;
          }
        }
        if (items["Opt-Out Payment After"]) {
          if (items["Opt-Out Payment After"][`${companyB.name}`] && !companyB["Opt-Out Payment After"]) {
            newFieldsB["Opt-Out Payment After"] = true;
            reRender = true;
          }
        }
      }

      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    } else if (type === "Insurance") {
      showItems.forEach((item) => {
        reRender = false;
        if (items[item]) {
          const company = item.replace(" Plan", "");
          let newCompany;
          if (companyA.name === company || companyA.altName === company) {
            newCompany = companyA;
            const rowTitles = Object.keys(items[company]);
            rowTitles.forEach((title) => {
              if (!newCompany.insuranceFields[title] && (items[item][title][0] || items[item][title][1])) {
                newCompany.insuranceFields[title] = true;
                reRender = "companyA";
              }
            });
          } else if (companyB.name === company || companyB.altName === company) {
            newCompany = companyB;
            const rowTitles = Object.keys(items[company]);
            rowTitles.forEach((title) => {
              if (!newCompany.insuranceFields[title] && (items[item][title][0] || items[item][title][1])) {
                newCompany.insuranceFields[title] = true;
                reRender = "companyB";
              }
            });
          }
          if (reRender) handleSubShow(reRender, newCompany);
        }
      });
    } else if (type === "Out-Of-Network") {
      const newOutOfNetworkFields = showSubRows["Out-Of-Network"];
      showItems.forEach((item) => {
        if (!newOutOfNetworkFields[item] && (items[item][0] > 0 || items[item][1] > 0)) {
          newOutOfNetworkFields[item] = true;
          reRender = "Out-Of-Network";
        }
      });
      if (reRender) handleSubShow(reRender, newOutOfNetworkFields);
    } else if (type === "Not Covered") {
      const notCoveredItems = items["Not Covered"];
      let newFieldsA = {};
      let newFieldsB = {};

      const companyANotCovered =
        (notCoveredItems && notCoveredItems[companyA.name]) ||
        (notCoveredItems && notCoveredItems[companyA.altName]) ||
        [];
      if (companyANotCovered && companyANotCovered.some((el) => el > 0)) {
        if (!companyA[type]) {
          newFieldsA[type] = true;
          reRender = true;
        }
      }
      const companyBNotCovered =
        (notCoveredItems && notCoveredItems[companyB.name]) ||
        (notCoveredItems && notCoveredItems[companyB.altName]) ||
        [];
      if (companyBNotCovered && companyBNotCovered.some((el) => el > 0)) {
        if (!companyB[type]) {
          newFieldsB[type] = true;
          reRender = true;
        }
      }
      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    } else if (showItems) {
      const currentType = period ? `${type} ${period}` : type;
      let newFieldsA = {};
      let newFieldsB = {};

      if (!companyA[currentType] && items[companyA.name]) {
        newFieldsA[currentType] = true;
        reRender = true;
      }
      if (!companyB[currentType] && items[companyB.name]) {
        newFieldsB[currentType] = true;
        reRender = true;
      }
      if (reRender) handleSubShow("companies", null, newFieldsA, newFieldsB);
    }

    const category = period ? `${type} ${period}` : type;
    const rowType = showRows[category];

    const renderItem = ({ item, fontSize = "14", category, subCategory, underline, key = "0", hideData = false }) => (
      <Row color="tertiary" justify="center" hide={!rowType} key={key} category={category} subCategory={subCategory}>
        {hideData ? (
          <></>
        ) : (
          <>
            <TableCell tall middle>
              <Text grey fontSize={fontSize} center underline={underline}>
                {formatCurrency(items[item] ? items[item][1] : 0)}
              </Text>
            </TableCell>
            <TableCell tall width="20px"></TableCell>
            <TableCell tall middle>
              <Text grey fontSize={fontSize} center underline={underline}>
                {formatCurrency(items[item] ? items[item][0] : 0)}
              </Text>
            </TableCell>
          </>
        )}
      </Row>
    );

    const renderInsuranceSubItems = (company, show) => {
      const { insuranceFields = {}, name, altName } = company;
      const { Deductible, Copay, Coinsurance } = insuranceFields;
      const companyDetails = items[name] || items[altName] || {};
      if (Deductible || Copay || Coinsurance) {
        return (
          <>
            <Row color="tertiary" justify="center" category>
              {expand[`Insurance ${show}`] ? (
                <></>
              ) : (
                <>
                  {/* Company name */}
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(companyDetails.Total ? companyDetails.Total[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(companyDetails.Total ? companyDetails.Total[0] : 0)}
                    </Text>
                  </TableCell>
                </>
              )}
            </Row>

            <Collapse isOpened={expand[`Insurance ${show}`]}>
              {Deductible && (
                <Row color="tertiary" justify="center" subCategory>
                  <TableCell tall middle>
                    <Text grey fontSize="12" center>
                      {formatCurrency(companyDetails.Deductible ? companyDetails.Deductible[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="12" center>
                      {formatCurrency(companyDetails.Deductible ? companyDetails.Deductible[0] : 0)}
                    </Text>
                  </TableCell>
                </Row>
              )}
              {Copay && (
                <Row color="tertiary" justify="center" subCategory>
                  <TableCell tall middle>
                    <Text grey fontSize="12" center>
                      {formatCurrency(companyDetails.Copay ? companyDetails.Copay[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="12" center>
                      {formatCurrency(companyDetails.Copay ? companyDetails.Copay[0] : 0)}
                    </Text>
                  </TableCell>
                </Row>
              )}
              {Coinsurance && (
                <Row color="tertiary" justify="center" subCategory>
                  <TableCell tall middle>
                    <Text grey fontSize="12" center>
                      {formatCurrency(companyDetails.Coinsurance ? companyDetails.Coinsurance[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="12" center>
                      {formatCurrency(companyDetails.Coinsurance ? companyDetails.Coinsurance[0] : 0)}
                    </Text>
                  </TableCell>
                </Row>
              )}
              <Row color="tertiary" justify="center" category>
                {/* Company name */}
                <TableCell tall middle>
                  <Text grey fontSize="14" center>
                    {formatCurrency(companyDetails.Total ? companyDetails.Total[1] : 0)}
                  </Text>
                </TableCell>
                <TableCell tall width="20px" />
                <TableCell tall middle>
                  <Text grey fontSize="14" center>
                    {formatCurrency(companyDetails.Total ? companyDetails.Total[0] : 0)}
                  </Text>
                </TableCell>
              </Row>
            </Collapse>
          </>
        );
      }
    };

    const renderPremiumSubItems = (period, isPremiumsAfter, totalNode) => {
      const companyAPremiums = companyA[period]; // true / false
      const companyBPremiums = companyB[period]; // true / false
      return (
        <>
          <Collapse isOpened={expand[period] || !isPremiumsAfter}>
            {companyAPremiums && (
              <>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  {expand["Company Details A"] ? (
                    <></>
                  ) : (
                    <>
                      <TableCell tall middle>
                        <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                          {formatCurrency(
                            items[period] && items[period][companyA.name] ? items[period][companyA.name][1] : 0,
                          )}
                        </Text>
                      </TableCell>
                      <TableCell tall width="20px" />
                      <TableCell tall middle>
                        <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                          {formatCurrency(
                            items[period] && items[period][companyA.name] ? items[period][companyA.name][0] : 0,
                          )}
                        </Text>
                      </TableCell>
                    </>
                  )}
                </Row>
              </>
            )}
            <Collapse isOpened={expand["Company Details A"]}>
              <>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period].PremiumSubRows && items[period].PremiumSubRows[companyA.name]
                          ? items[period].PremiumSubRows[companyA.name].Premium
                          : 0,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period].PremiumSubRows && items[period].PremiumSubRows[companyA.name]
                          ? items[period].PremiumSubRows[companyA.name].Premium
                          : 0,
                      )}
                    </Text>
                  </TableCell>
                </Row>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period].PremiumSubRows && items[period].PremiumSubRows[companyA.name]
                          ? items[period].PremiumSubRows[companyA.name].SpousalSurcharges
                          : 0,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period].PremiumSubRows && items[period].PremiumSubRows[companyA.name]
                          ? items[period].PremiumSubRows[companyA.name].SpousalSurcharges
                          : 0,
                      )}
                    </Text>
                  </TableCell>
                </Row>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period][companyA.name] ? items[period][companyA.name][1] : 0,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period][companyA.name] ? items[period][companyA.name][0] : 0,
                      )}
                    </Text>
                  </TableCell>
                </Row>
              </>
            </Collapse>

            {companyBPremiums && (
              <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                {expand["Company Details B"] ? (
                  <></>
                ) : (
                  <>
                    <TableCell tall middle>
                      <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                        {formatCurrency(
                          items[period] && items[period][companyB.name] ? items[period][companyB.name][1] : 0,
                        )}
                      </Text>
                    </TableCell>
                    <TableCell tall width="20px" />
                    <TableCell tall middle>
                      <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                        {formatCurrency(
                          items[period] && items[period][companyB.name] ? items[period][companyB.name][0] : 0,
                        )}
                      </Text>
                    </TableCell>
                  </>
                )}
              </Row>
            )}
            <Collapse isOpened={expand["Company Details B"]}>
              <>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period].PremiumSubRows && items[period].PremiumSubRows[companyB.name]
                          ? items[period].PremiumSubRows[companyB.name].Premium
                          : 0,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period].PremiumSubRows && items[period].PremiumSubRows[companyB.name]
                          ? items[period].PremiumSubRows[companyB.name].Premium
                          : 0,
                      )}
                    </Text>
                  </TableCell>
                </Row>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period].PremiumSubRows && items[period].PremiumSubRows[companyB.name]
                          ? items[period].PremiumSubRows[companyB.name].SpousalSurcharges
                          : 0,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period].PremiumSubRows && items[period].PremiumSubRows[companyB.name]
                          ? items[period].PremiumSubRows[companyB.name].SpousalSurcharges
                          : 0,
                      )}
                    </Text>
                  </TableCell>
                </Row>
                <Row color="tertiary" justify="center" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period][companyB.name] ? items[period][companyB.name][1] : 0,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize={isPremiumsAfter ? "12" : "14"} center>
                      {formatCurrency(
                        items[period] && items[period][companyB.name] ? items[period][companyB.name][0] : 0,
                      )}
                    </Text>
                  </TableCell>
                </Row>
              </>
            </Collapse>
            {isPremiumsAfter ? <>{totalNode}</> : <></>}
          </Collapse>
        </>
      );
    };

    const renderSurchargeSubItems = (period, isSurchargesAfter, totalNode) => {
      const companyASurcharges = companyA[period]; // true / false
      const companyBSurcharges = companyB[period]; // true / false

      return (
        <Collapse isOpened={expand[period] || !isSurchargesAfter}>
          {companyASurcharges && (
            <Row color="tertiary" justify="center" category={!isSurchargesAfter} subCategory={isSurchargesAfter}>
              <TableCell tall middle>
                <Text grey fontSize={isSurchargesAfter ? "12" : "14"} center>
                  {formatCurrency(items[period] && items[period][companyA.name] ? items[period][companyA.name][1] : 0)}
                </Text>
              </TableCell>
              <TableCell tall width="20px" />
              <TableCell tall middle>
                <Text grey fontSize={isSurchargesAfter ? "12" : "14"} center>
                  {formatCurrency(items[period] && items[period][companyA.name] ? items[period][companyA.name][0] : 0)}
                </Text>
              </TableCell>
            </Row>
          )}
          {companyBSurcharges && (
            <Row color="tertiary" justify="center" category={!isSurchargesAfter} subCategory={isSurchargesAfter}>
              <TableCell tall middle>
                <Text grey fontSize={isSurchargesAfter ? "12" : "14"} center>
                  {formatCurrency(items[period] && items[period][companyB.name] ? items[period][companyB.name][1] : 0)}
                </Text>
              </TableCell>
              <TableCell tall width="20px" />
              <TableCell tall middle>
                <Text grey fontSize={isSurchargesAfter ? "12" : "14"} center>
                  {formatCurrency(items[period] && items[period][companyB.name] ? items[period][companyB.name][0] : 0)}
                </Text>
              </TableCell>
            </Row>
          )}
          {totalNode}
        </Collapse>
      );
    };

    const renderOptOutPaymentSubItems = (period, isOptOutPaymentAfter, totalNode) => {
      const companyAOptOutPayment = companyA[period]; // true / false
      const companyBOptOutPayment = companyB[period]; // true / false

      return (
        <Collapse isOpened={expand[period] || !isOptOutPaymentAfter}>
          {companyAOptOutPayment && (
            <Row color="tertiary" justify="center" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
              <TableCell tall middle>
                <Text grey fontSize={isOptOutPaymentAfter ? "12" : "14"} center>
                  {formatCurrency(items[period] && items[period][companyA.name] ? items[period][companyA.name][1] : 0)}
                </Text>
              </TableCell>
              <TableCell tall width="20px" />
              <TableCell tall middle>
                <Text grey fontSize={isOptOutPaymentAfter ? "12" : "14"} center>
                  {formatCurrency(items[period] && items[period][companyA.name] ? items[period][companyA.name][0] : 0)}
                </Text>
              </TableCell>
            </Row>
          )}
          {companyBOptOutPayment && (
            <Row color="tertiary" justify="center" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
              <TableCell tall middle>
                <Text grey fontSize={isOptOutPaymentAfter ? "12" : "14"} center>
                  {formatCurrency(items[period] && items[period][companyB.name] ? items[period][companyB.name][1] : 0)}
                </Text>
              </TableCell>
              <TableCell tall width="20px" />
              <TableCell tall middle>
                <Text grey fontSize={isOptOutPaymentAfter ? "12" : "14"} center>
                  {formatCurrency(items[period] && items[period][companyB.name] ? items[period][companyB.name][0] : 0)}
                </Text>
              </TableCell>
            </Row>
          )}
          {totalNode}
        </Collapse>
      );
    };

    const renderNotCoveredSubItems = ({ company }) => {
      const costs = items["Not Covered"][company.name] || items["Not Covered"][company.altName] || [0, 0];

      return (
        <Row color="tertiary" justify="center" hide={!rowType} key={company.name} category={category}>
          <TableCell tall middle>
            <Text grey fontSize={"14"} center>
              {formatCurrency(costs[1])}
            </Text>
          </TableCell>
          <TableCell tall width="20px"></TableCell>
          <TableCell tall middle>
            <Text grey fontSize={"14"} center>
              {formatCurrency(costs[0])}
            </Text>
          </TableCell>
        </Row>
      );
    };

    // render subRows
    if (type === "Premium") {
      const isPremiumsAfter = companyA["Premiums After"] || companyB["Premiums After"];
      return (
        <Collapse isOpened={expand[category]}>
          {isPremiumsAfter && (
            <Row color="tertiary" justify="center" category>
              {expand["Premiums Before"] ? (
                <></>
              ) : (
                <>
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Premiums Before"] ? items["Premiums Before"].Total[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Premiums Before"] ? items["Premiums Before"].Total[0] : 0)}
                    </Text>
                  </TableCell>
                </>
              )}
            </Row>
          )}
          {renderPremiumSubItems(
            "Premiums Before",
            isPremiumsAfter,
            <Row color="tertiary" justify="center" category>
              <TableCell tall middle>
                <Text grey fontSize="14" center>
                  {formatCurrency(items["Premiums Before"] ? items["Premiums Before"].Total[1] : 0)}
                </Text>
              </TableCell>
              <TableCell tall width="20px" />
              <TableCell tall middle>
                <Text grey fontSize="14" center>
                  {formatCurrency(items["Premiums Before"] ? items["Premiums Before"].Total[0] : 0)}
                </Text>
              </TableCell>
            </Row>,
          )}
          {isPremiumsAfter && (
            <>
              <Row color="tertiary" justify="center" category>
                {expand["Premiums After"] ? (
                  <></>
                ) : (
                  <>
                    <TableCell tall middle>
                      <Text grey fontSize="14" center>
                        {formatCurrency(items["Premiums After"] ? items["Premiums After"].Total[1] : 0)}
                      </Text>
                    </TableCell>
                    <TableCell tall width="20px" />
                    <TableCell tall middle>
                      <Text grey fontSize="14" center>
                        {formatCurrency(items["Premiums After"] ? items["Premiums After"].Total[0] : 0)}
                      </Text>
                    </TableCell>
                  </>
                )}
              </Row>
              {renderPremiumSubItems(
                "Premiums After",
                isPremiumsAfter,
                <Row color="tertiary" justify="center" category>
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Premiums After"] ? items["Premiums After"].Total[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Premiums After"] ? items["Premiums After"].Total[0] : 0)}
                    </Text>
                  </TableCell>
                </Row>,
              )}
            </>
          )}
          {totalElement}
        </Collapse>
      );
    } else if (type === "Surcharge") {
      const isSurchargesAfter = companyA["Surcharges After"] || companyB["Surcharges After"];
      return (
        <Collapse isOpened={expand[category]}>
          {isSurchargesAfter && (
            <Row color="tertiary" justify="center" category>
              {expand["Surcharges Before"] ? (
                <></>
              ) : (
                <>
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Surcharges Before"] ? items["Surcharges Before"].Total[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Surcharges Before"] ? items["Surcharges Before"].Total[0] : 0)}
                    </Text>
                  </TableCell>
                </>
              )}
            </Row>
          )}
          {renderSurchargeSubItems(
            "Surcharges Before",
            isSurchargesAfter,
            <Row color="tertiary" justify="center" category>
              <TableCell tall middle>
                <Text grey fontSize="14" center>
                  {formatCurrency(items["Surcharges Before"] ? items["Surcharges Before"].Total[1] : 0)}
                </Text>
              </TableCell>
              <TableCell tall width="20px" />
              <TableCell tall middle>
                <Text grey fontSize="14" center>
                  {formatCurrency(items["Surcharges Before"] ? items["Surcharges Before"].Total[0] : 0)}
                </Text>
              </TableCell>
            </Row>,
          )}
          {isSurchargesAfter && (
            <>
              <Row color="tertiary" justify="center" category>
                {expand["Surcharges After"] ? (
                  <></>
                ) : (
                  <>
                    <TableCell tall middle>
                      <Text grey fontSize="14" center>
                        {formatCurrency(items["Surcharges After"] ? items["Surcharges After"].Total[1] : 0)}
                      </Text>
                    </TableCell>
                    <TableCell tall width="20px" />
                    <TableCell tall middle>
                      <Text grey fontSize="14" center>
                        {formatCurrency(items["Surcharges After"] ? items["Surcharges After"].Total[0] : 0)}
                      </Text>
                    </TableCell>
                  </>
                )}
              </Row>
              {renderSurchargeSubItems(
                "Surcharges After",
                isSurchargesAfter,
                <Row color="tertiary" justify="center" category>
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Surcharges After"] ? items["Surcharges After"].Total[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Surcharges After"] ? items["Surcharges After"].Total[0] : 0)}
                    </Text>
                  </TableCell>
                </Row>,
              )}
            </>
          )}
          {totalElement}
        </Collapse>
      );
    } else if (type === "Insurance") {
      return (
        <Collapse isOpened={expand[category]}>
          {renderInsuranceSubItems(companyA, "companyA")}
          {renderInsuranceSubItems(companyB, "companyB")}
          {totalElement}
        </Collapse>
      );
    } else if (type === "Out-Of-Network") {
      const outOfNetwork = showSubRows["Out-Of-Network"];

      return (
        <Collapse isOpened={expand[category]}>
          {renderItem({
            item: "Cost of Visits",
            fontSize: "14",
            category: true,
          })}

          {renderItem({
            item: "Reimbursement from Insurance",
            fontSize: "14",
            category: true,
            hideData: expand["Reimbursement from Insurance"],
          })}
          <Collapse isOpened={expand["Reimbursement from Insurance"]}>
            {outOfNetwork["Negotiated Rate"] &&
              renderItem({
                item: "Negotiated Rate",
                fontSize: "12",
                subCategory: true,
              })}

            {outOfNetwork["Out-of-Pocket Accrual"] &&
              renderItem({
                item: "Out-of-Pocket Accrual",
                fontSize: "12",
                subCategory: true,
                hideData: expand["Out-of-Pocket Accrual"],
              })}
            <Collapse isOpened={expand["Out-of-Pocket Accrual"]}>
              {outOfNetwork["Deductibles"] &&
                renderItem({
                  item: "Deductibles",
                  fontSize: "10",
                  subCategory: true,
                })}
              {outOfNetwork["Coinsurance"] &&
                renderItem({
                  item: "Coinsurance",
                  fontSize: "10",
                  subCategory: true,
                })}
              {outOfNetwork["Out-of-Pocket Accrual"] &&
                renderItem({
                  item: "Out-of-Pocket Accrual",
                  fontSize: "12",
                  subCategory: true,
                })}
            </Collapse>
            {renderItem({
              item: "Reimbursement from Insurance",
              fontSize: "14",
              category: true,
            })}
          </Collapse>
          {totalElement}
        </Collapse>
      );
    } else if (type === "Not Covered") {
      return (
        <Collapse isOpened={expand[category]}>
          {companyA[type] &&
            renderNotCoveredSubItems({
              company: companyA,
            })}
          {companyB[type] &&
            renderNotCoveredSubItems({
              company: companyB,
            })}
          {totalElement}
        </Collapse>
      );
    } else if (type === "Opt-Out Payment") {
      const isOptOutPaymentAfter = companyA["Opt-Out Payment After"] || companyB["Opt-Out Payment After"];
      return (
        <Collapse isOpened={expand[category]}>
          {isOptOutPaymentAfter && (
            <Row color="tertiary" justify="center" category>
              {expand["Opt-Out Payment Before"] ? (
                <></>
              ) : (
                <>
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Opt-Out Payment Before"] ? items["Opt-Out Payment Before"].Total[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Opt-Out Payment Before"] ? items["Opt-Out Payment Before"].Total[0] : 0)}
                    </Text>
                  </TableCell>
                </>
              )}
            </Row>
          )}
          {renderOptOutPaymentSubItems(
            "Opt-Out Payment Before",
            isOptOutPaymentAfter,
            <Row color="tertiary" justify="center" category>
              <TableCell tall middle>
                <Text grey fontSize="14" center>
                  {formatCurrency(items["Opt-Out Payment Before"] ? items["Opt-Out Payment Before"].Total[1] : 0)}
                </Text>
              </TableCell>
              <TableCell tall width="20px" />
              <TableCell tall middle>
                <Text grey fontSize="14" center>
                  {formatCurrency(items["Opt-Out Payment Before"] ? items["Opt-Out Payment Before"].Total[0] : 0)}
                </Text>
              </TableCell>
            </Row>,
          )}

          {isOptOutPaymentAfter && (
            <>
              <Row color="tertiary" justify="center" category>
                {expand["Opt-Out Payment After"] ? (
                  <></>
                ) : (
                  <>
                    <TableCell tall middle>
                      <Text grey fontSize="14" center>
                        {formatCurrency(items["Opt-Out Payment After"] ? items["Opt-Out Payment After"].Total[1] : 0)}
                      </Text>
                    </TableCell>
                    <TableCell tall width="20px" />
                    <TableCell tall middle>
                      <Text grey fontSize="14" center>
                        {formatCurrency(items["Opt-Out Payment After"] ? items["Opt-Out Payment After"].Total[0] : 0)}
                      </Text>
                    </TableCell>
                  </>
                )}
              </Row>
              {renderOptOutPaymentSubItems(
                "Opt-Out Payment After",
                isOptOutPaymentAfter,
                <Row color="tertiary" justify="center" category>
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Opt-Out Payment After"] ? items["Opt-Out Payment After"].Total[1] : 0)}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px" />
                  <TableCell tall middle>
                    <Text grey fontSize="14" center>
                      {formatCurrency(items["Opt-Out Payment After"] ? items["Opt-Out Payment After"].Total[0] : 0)}
                    </Text>
                  </TableCell>
                </Row>,
              )}
            </>
          )}
          {totalElement}
        </Collapse>
      );
    } else if (type === "HSA" || type === "HRA") {
      const currentType = `${type} ${period}`;

      return (
        <Collapse isOpened={expand[category]}>
          {companyA[currentType] &&
            renderItem({
              item: companyA.name,
              fontSize: "14",
              category: true,
              key: 0,
            })}
          {companyB[currentType] &&
            renderItem({
              item: companyB.name,
              fontSize: "14",
              category: true,
              key: 1,
            })}
          {totalElement}
        </Collapse>
      );
    }
  };

  // render row cost
  const renderCost = ({ type, data, event, period }) => {
    const { before, after, expectedBefore, expectedAfter } = data;

    const unExpectedCost = getValue(before, after, period);
    if (unExpectedCost) handleShow(period ? `${type} ${period}` : type, true);

    const expectedCost = getValue(expectedBefore, expectedAfter, period);
    if (expectedCost) handleShow(period ? `${type} ${period}` : type, true);

    const modifier = (type === "HSA" || type === "HRA" || type === "Opt-Out Payment") && period !== "After" ? -1 : 1;

    const category = period ? `${type} ${period}` : type;

    return (
      <>
        <Row color="tertiary" justify="center" hide={!showRows[period ? `${type} ${period}` : type]}>
          {!expand[category] ? (
            <>
              <TableCell tall middle>
                <Text black small center>
                  {formatCurrency(expectedCost * modifier)}
                </Text>
              </TableCell>
              <TableCell tall width="20px"></TableCell>
              <TableCell tall middle>
                <Text black small center>
                  {formatCurrency(unExpectedCost * modifier)}
                </Text>
              </TableCell>
            </>
          ) : (
            <></>
          )}
        </Row>
        {renderSubCost(
          before ? before.details : [],
          after ? after.details : [],
          unExpectedCost,
          expectedBefore ? expectedBefore.details : [],
          expectedAfter ? expectedAfter.details : [],
          expectedCost,
          period,
          type,
          <Row color="tertiary" justify="center" hide={!showRows[period ? `${type} ${period}` : type]}>
            <TableCell tall middle>
              <Text black small center>
                {formatCurrency(expectedCost * modifier)}
              </Text>
            </TableCell>
            <TableCell tall width="20px"></TableCell>
            <TableCell tall middle>
              <Text black small center>
                {formatCurrency(unExpectedCost * modifier)}
              </Text>
            </TableCell>
          </Row>,
        )}
      </>
    );
  };

  const handleAddServices = (report) => {
    localStorage.removeItem("servicesForm");
    history.push(`/add-services`, {
      reportId: report._id,
      returnToDetailsPage: true,
      activeTab,
      report: report,
    });
  };

  // render risk column
  const renderRisk = (opt, id) => {
    const { expected, unexpected, optimizationDetails } = opt || {};
    const { birthParent, partner } = optimizationDetails || {};

    const isToSelect = opt && isEmpty(opt);

    // const { total, charges } = unexpected
    const preference = preferences[id];

    const { lifeEvent = {} } = preference || {};

    let premiumCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      surchargeCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      insuranceCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      outOfNetworkCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      optOutPayments = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      hsaCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      hraCharges = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      },
      notCovered = {
        before: null,
        after: null,
        expectedBefore: null,
        expectedAfter: null,
      };

    if (unexpected && unexpected.charges) {
      const { charges } = unexpected;
      premiumCharges.before = charges.before.find((charge) => charge.id === "premium");
      premiumCharges.after = charges.after.find((charge) => charge.id === "premium");
      optOutPayments.before = charges.before.find((charge) => charge.id === "opt-out payment");
      optOutPayments.after = charges.after.find((charge) => charge.id === "opt-out payment");
      surchargeCharges.before = charges.before.find((charge) => charge.id === "surcharge");
      surchargeCharges.after = charges.after.find((charge) => charge.id === "surcharge");
      insuranceCharges.before = charges.before.find((charge) => charge.id === "insurance");
      outOfNetworkCharges.before = charges.before.find((charge) => charge.id === "out-of-network");
      notCovered.before = charges.before.find((charge) => charge.id === "not covered");
      hsaCharges.before = charges.before.find((charge) => charge.id === "hsa");
      hsaCharges.after = charges.after.find((charge) => charge.id === "hsa");
      hraCharges.before = charges.before.find((charge) => charge.id === "hra");
      hraCharges.after = charges.after.find((charge) => charge.id === "hra");
    }

    if (expected && expected.charges) {
      const { charges } = expected;
      premiumCharges.expectedBefore = charges.before.find((charge) => charge.id === "premium");
      premiumCharges.expectedAfter = charges.after.find((charge) => charge.id === "premium");
      optOutPayments.expectedBefore = charges.before.find((charge) => charge.id === "opt-out payment");
      optOutPayments.expectedAfter = charges.after.find((charge) => charge.id === "opt-out payment");
      surchargeCharges.expectedBefore = charges.before.find((charge) => charge.id === "surcharge");
      surchargeCharges.expectedAfter = charges.after.find((charge) => charge.id === "surcharge");
      insuranceCharges.expectedBefore = charges.before.find((charge) => charge.id === "insurance");
      outOfNetworkCharges.expectedBefore = charges.before.find((charge) => charge.id === "out-of-network");
      notCovered.expectedBefore = charges.before.find((charge) => charge.id === "not covered");
      hsaCharges.expectedBefore = charges.before.find((charge) => charge.id === "hsa");
      hsaCharges.expectedAfter = charges.after.find((charge) => charge.id === "hsa");
      hraCharges.expectedBefore = charges.before.find((charge) => charge.id === "hra");
      hraCharges.expectedAfter = charges.after.find((charge) => charge.id === "hra");
    }

    const getInsurance = (parent) => {
      const { company, network, plan } = parent || {};
      let insurance = "";
      if (company) {
        insurance = `${company} ${network} ${plan}`;
      }
      return (
        <Text blue bold small center lineHeightUnits="20px">
          {insurance}
        </Text>
      );
    };

    const removePreference = async (custom) => {
      const token = await getToken();
      const reset =
        custom === "custom"
          ? { customPreferenceOne: {}, report: { _id: report._id } }
          : { customPreferenceTwo: {}, report: { _id: report._id } };

      await disablePreference(report._id, custom, token);
      dispatch(Actions.Creators.switchState(reset));
    };

    const expectedServicesTitle = report.expected.title;
    const unexpectedServicesTitle = report.unexpected.title;

    return (
      <>
        <Row
          col
          selectHeader
          style={{ width: isToSelect && "100%", padding: isToSelect && "0px" }}
          className="custom-select"
          customWidth="100%"
        >
          {isToSelect ? (
            <CustomSelect onClick={() => setCustomPrefrenceModal(id)} />
          ) : (
            <div
              style={{
                padding: 20,
                border: "2px solid #D0D5DD",
                borderRadius: "8px",
                position: "relative",
                height: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                flexDirection: "column",
                width: "100%",
              }}
            >
              {!(id === "custom" || id === "custom2") ? (
                <div style={{ position: "absolute", top: 5, right: 5 }} title="Recommended">
                  <svg
                    style={{ width: "20px", height: "20px", fill: "#FDB022" }}
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 24 24"
                    fill="currentColor"
                  >
                    <path
                      fillRule="evenodd"
                      d="M10.788 3.21c.448-1.077 1.976-1.077 2.424 0l2.082 5.007 5.404.433c1.164.093 1.636 1.545.749 2.305l-4.117 3.527 1.257 5.273c.271 1.136-.964 2.033-1.96 1.425L12 18.354 7.373 21.18c-.996.608-2.231-.29-1.96-1.425l1.257-5.273-4.117-3.527c-.887-.76-.415-2.212.749-2.305l5.404-.433 2.082-5.006z"
                      clipRule="evenodd"
                    />
                  </svg>
                </div>
              ) : (
                <></>
              )}
              {getInsurance(birthParent)}
              {getInsurance(partner)}
            </div>
          )}
        </Row>
        <br></br>
        <Badge
          count={
            (id === "custom" || id === "custom2") &&
            !isToSelect && (
              <div className="iconContainer2" onClick={() => removePreference(id)}>
                <MinusOutlined style={{ fontSize: 20, color: "white" }} twoToneColor="#ff5252" />
              </div>
            )
          }
        >
          <OptWrapper opacity={isToSelect}>
            {/* {report?.birthParent.canEditPlans ? (
              <Popconfirm
                title="Would you like to edit your visits?"
                okText="Yes"
                cancelText="No"
                onConfirm={() => {
                  dispatch(PlanActions.Creators.changeCurrent(0));
                  handleAddServices(report);
                }}
              >
                <Row color="primary" header hover cursor="pointer">
                  <TableCell tall middle>
                    <Text white small center>
                      {expectedServicesTitle}
                    </Text>
                  </TableCell>
                  <TableCell tall width="20px"></TableCell>
                  <TableCell tall middle>
                    <Text white small center>
                      {unexpectedServicesTitle}
                    </Text>
                  </TableCell>
                </Row>
              </Popconfirm>
            ) : ( */}
            <Row color="primary" header>
              <TableCell tall middle headerBgColor padding="6px" bRadius="4px">
                <Text small center headerColor bold>
                  {expectedServicesTitle}
                </Text>
              </TableCell>
              <TableCell tall width="20px" white></TableCell>
              <TableCell tall middle headerBgColor padding="6px" bRadius="4px">
                <Text small center headerColor bold>
                  {unexpectedServicesTitle}
                </Text>
              </TableCell>
            </Row>
            {/* )} */}

            {expected ? (
              <>
                <Collapse isOpened={expand.total}>
                  {renderCost({
                    type: "Premium",
                    data: premiumCharges,
                    event: lifeEvent.name,
                  })}
                  {renderCost({
                    type: "Surcharge",
                    data: surchargeCharges,
                    event: lifeEvent.name,
                  })}
                  {renderCost({
                    type: "Insurance",
                    data: insuranceCharges,
                    event: lifeEvent.name,
                  })}
                  {renderCost({
                    type: "Out-Of-Network",
                    data: outOfNetworkCharges,
                    event: lifeEvent.name,
                  })}
                  {renderCost({
                    type: "Opt-Out Payment",
                    data: optOutPayments,
                    event: lifeEvent.name,
                  })}
                  {renderCost({
                    type: "HSA",
                    data: hsaCharges,
                    event: lifeEvent.name,
                    period: "Before",
                  })}
                  {renderCost({
                    type: "HRA",
                    data: hraCharges,
                    event: lifeEvent.name,
                    period: "Before",
                  })}
                  {renderCost({
                    type: "Not Covered",
                    data: notCovered,
                    event: lifeEvent.name,
                  })}
                </Collapse>
                <Row
                  color={expand.total || expand.leftover ? "quaternary" : "tertiary"}
                  bgColor="#E8EEFA"
                  style={{
                    boxShadow: "60px 0px #E8EEFA",
                  }}
                >
                  <TableCell tall middle>
                    <Text medium bold center headerColor>
                      {formatCurrency(
                        preferences["1"].hsaHraImpact === "spending-only"
                          ? expected.total
                          : expected.grandTotalWithHsaHraImpact,
                      )}
                    </Text>
                  </TableCell>
                  <TableCell tall padding="0" width="40px" />
                  <TableCell tall middle>
                    <Text medium bold center headerColor>
                      {formatCurrency(
                        preferences["1"].hsaHraImpact === "spending-only"
                          ? unexpected.total
                          : unexpected.grandTotalWithHsaHraImpact,
                      )}{" "}
                    </Text>
                  </TableCell>
                </Row>
                <Collapse isOpened={expand.leftover}>
                  <Row header />
                  {renderCost({
                    type: "HSA",
                    data: hsaCharges,
                    event: lifeEvent.name,
                    period: "After",
                  })}
                  {renderCost({
                    type: "HRA",
                    data: hraCharges,
                    event: lifeEvent.name,
                    period: "After",
                  })}
                </Collapse>
              </>
            ) : (
              <Row color="tertiary">
                <TableCell tall middle>
                  <Text black lg bold center>
                    $-
                  </Text>
                </TableCell>
                <TableCell tall padding="0" width="40px">
                  <Icon blackArrow />
                </TableCell>
                <TableCell tall middle>
                  <Text black lg bold center>
                    $-
                  </Text>
                </TableCell>
              </Row>
            )}
          </OptWrapper>
        </Badge>
      </>
    );
  };

  const renderSubItemTitles = ({
    item,
    fontSize,
    category,
    subCategory,
    underline,
    isExpand,
    customTitle,
    level,
    useCustomColor,
  }) => (
    <Row justify="start" category={category} subCategory={subCategory}>
      {isExpand ? (
        <Link
          color={colorFromLevel(expand[item] ? level || 0 : -1)}
          bold
          fontSize={16}
          lHeight={"1rem"}
          justBetween
          noMargin
          onClick={() => handleExpand(item)}
        >
          {customTitle ? customTitle : item}
          <IconWrapper type={expand[item] ? "up" : "down"} />
        </Link>
      ) : (
        <Text
          color={useCustomColor ? colorFromLevel(expand[item] ? level || 0 : -1) : colorFromLevel(-1)}
          fontSize={fontSize}
          bold
          underline={underline}
        >
          {customTitle ? customTitle : item}
        </Text>
      )}
    </Row>
  );

  const getSelectedReport = () => {
    const subheadings = [
      customOptimizations[0]?.selected.subHeading[0] || null,
      customOptimizations[0]?.selected.subHeading[1] || null,
    ];

    for (let allOptimization of allOptimizations) {
      if (
        allOptimization.selected.subHeading[0] == subheadings[0] &&
        allOptimization.selected.subHeading[1] == subheadings[1]
      ) {
        return allOptimization.selected;
      }
    }

    return allOptimizations?.[0]?.selected;
  };

  const getFirstCustomPreference = () => {
    const subheadings = [customPreferenceOne?.subHeading?.[0] || null, customPreferenceOne?.subHeading?.[1] || null];

    for (let allOptimization of allOptimizations) {
      if (
        allOptimization.selected.subHeading[0] == subheadings[0] &&
        allOptimization.selected.subHeading[1] == subheadings[1]
      ) {
        return allOptimization.selected;
      }
    }

    return customPreferenceOne;
  };

  const getSecondCustomPreference = () => {
    const subheadings = [customPreferenceTwo?.subHeading?.[0] || null, customPreferenceTwo?.subHeading?.[1] || null];

    for (let allOptimization of allOptimizations) {
      if (
        allOptimization.selected.subHeading[0] == subheadings[0] &&
        allOptimization.selected.subHeading[1] == subheadings[1]
      ) {
        return allOptimization.selected;
      }
    }

    return customPreferenceTwo;
  };

  const renderInsuranceSubItemTitles = (company, show, level = 0) => {
    const { insuranceFields = {}, name } = company;
    const { Deductible, Copay, Coinsurance } = insuranceFields;

    if (Deductible || Copay || Coinsurance) {
      return (
        <>
          <Row justify="start" category>
            <Link
              color={colorFromLevel(expand[`Insurance ${show}`] ? level : -1)}
              bold
              fontSize={16}
              lHeight={"1rem"}
              justBetween
              onClick={() => handleExpand(`Insurance ${show}`)}
            >
              {name}
              <IconWrapper type={expand[`Insurance ${show}`] ? "up" : "down"} />
            </Link>
          </Row>

          <CustomCollapse level={level} isOpened={expand[`Insurance ${show}`]}>
            {Deductible && (
              <Row justify="start" subCategory>
                <Text grey fontSize="16" bold>
                  Deductible
                </Text>
              </Row>
            )}
            {Copay && (
              <Row justify="start" subCategory>
                <Text grey fontSize="16" bold>
                  Copay
                </Text>
              </Row>
            )}
            {Coinsurance && (
              <Row justify="start" subCategory>
                <Text grey fontSize="16" bold>
                  Coinsurance
                </Text>
              </Row>
            )}
            <Row justify="start" category>
              <Text
                color={colorFromLevel(expand[`Insurance ${show}`] ? level : -1)}
                bold
                fontSize={16}
                lHeight={"1rem"}
                justStart
              >
                Total
              </Text>
            </Row>
          </CustomCollapse>
        </>
      );
    }
  };

  const { companyA, companyB } = showSubRows;
  const isPremiumsAfter = companyA["Premiums After"] || companyB["Premiums After"]; // are there any Premium After

  const isSurchargesAfter = companyA["Surcharges After"] || companyB["Surcharges After"];

  const isOptOutPaymentAfter = companyA["Opt-Out Payment After"] || companyB["Opt-Out Payment After"];

  return (
    <Wrapper>
      <Header title="How much could I be expected to pay overall?" bigTitle />

      <FilterSortButton toggleSideBar={toggleSideBar} toggleAssumptions={toggleAssumptions} />

      <FlexWrapper hide={!report} justify="flex-start" align="start">
        <InnerWrapper header align="flex-end">
          <Row customHeight="135px">{/*Plan Name*/}</Row>
          <br></br>
          <Row padding={"4px 10px;"} labelsHeader />

          <Collapse isOpened={expand.total} initialStyle={{ padding: "0 0 0 10px" }}>
            <Collapse isOpened={showRows.Premium}>
              <Row justify="start">
                <Link
                  color={colorFromLevel(expand["Premium"] ? 1 : -1)}
                  bold
                  fontSize={16}
                  lHeight={"1rem"}
                  justBetween
                  onClick={() => handleExpand("Premium")}
                >
                  Premiums
                  <IconWrapper type={expand["Premium"] ? "up" : "down"} />
                </Link>
              </Row>
              <CustomCollapse level="1" isOpened={expand.Premium}>
                {isPremiumsAfter && (
                  <Row justify="start" category>
                    <Link
                      color={colorFromLevel(expand["Premiums Before"] ? 2 : -1)}
                      bold
                      fontSize={16}
                      lHeight={"1rem"}
                      justBetween
                      onClick={() => handleExpand("Premiums Before")}
                    >
                      Before Baby
                      <IconWrapper type={expand["Premiums Before"] ? "up" : "down"} />
                    </Link>
                  </Row>
                )}
                <CustomCollapse level="2" isOpened={expand["Premiums Before"] || !isPremiumsAfter}>
                  {companyA["Premiums Before"] && (
                    <>
                      <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                        <Link
                          color={colorFromLevel(expand["Company Details A"] ? 3 : -1)}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justBetween
                          onClick={() => handleExpand("Company Details A")}
                        >
                          {companyA.name}
                          <IconWrapper type={expand["Company Details A"] ? "up" : "down"} />
                        </Link>
                      </Row>
                      <CustomCollapse level="3" isOpened={expand["Company Details A"]}>
                        <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                          <Text fontSize="16" bold>
                            Premium
                          </Text>
                        </Row>
                        <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                          <Text fontSize="16" bold>
                            Spousal Surcharge
                          </Text>
                        </Row>
                        <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                          <Text
                            color={colorFromLevel(expand["Company Details A"] ? 3 : -1)}
                            bold
                            fontSize={16}
                            lHeight={"1rem"}
                            justStart
                          >
                            Total
                          </Text>
                        </Row>
                      </CustomCollapse>
                    </>
                  )}

                  {companyB["Premiums Before"] && (
                    <>
                      <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                        <Link
                          color={colorFromLevel(expand["Company Details B"] ? 3 : -1)}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justBetween
                          onClick={() => handleExpand("Company Details B")}
                        >
                          {companyB.name}
                          <IconWrapper type={expand["Company Details B"] ? "up" : "down"} />
                        </Link>
                      </Row>
                      <CustomCollapse level="3" isOpened={expand["Company Details B"]}>
                        <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                          <Text fontSize="16" bold>
                            Premium
                          </Text>
                        </Row>
                        <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                          <Text fontSize="16" bold>
                            Spousal Surcharge
                          </Text>
                        </Row>
                        <Row justify="start" category={!isPremiumsAfter} subCategory={isPremiumsAfter}>
                          <Text
                            color={colorFromLevel(expand["Company Details B"] ? 3 : -1)}
                            bold
                            fontSize={16}
                            lHeight={"1rem"}
                            justStart
                          >
                            Total
                          </Text>
                        </Row>
                      </CustomCollapse>
                    </>
                  )}
                  {isPremiumsAfter && (
                    <Row justify="start" category>
                      <Text
                        color={colorFromLevel(expand["Premiums Before"] ? 2 : -1)}
                        bold
                        fontSize={16}
                        lHeight={"1rem"}
                        justStart
                      >
                        Total
                      </Text>
                    </Row>
                  )}
                </CustomCollapse>

                {isPremiumsAfter && (
                  <>
                    <Row justify="start" category>
                      <Link
                        color={colorFromLevel(expand["Premiums After"] ? 2 : -1)}
                        bold
                        fontSize={16}
                        lHeight={"1rem"}
                        justBetween
                        onClick={() => handleExpand("Premiums After")}
                      >
                        After Baby
                        <IconWrapper type={expand["Premiums After"] ? "up" : "down"} />
                      </Link>
                    </Row>
                    <CustomCollapse level="2" isOpened={expand["Premiums After"]}>
                      {companyA["Premiums After"] && (
                        <Row justify="start" subCategory>
                          <Text grey fontSize="16" bold>
                            {companyA.name}
                          </Text>
                        </Row>
                      )}
                      {companyB["Premiums After"] && (
                        <Row justify="start" subCategory>
                          <Text grey fontSize="16" bold>
                            {companyB.name}
                          </Text>
                        </Row>
                      )}
                      <Row justify="start" category>
                        <Text
                          color={colorFromLevel(expand["Premiums After"] ? 2 : -1)}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justStart
                        >
                          Total
                        </Text>
                      </Row>
                    </CustomCollapse>
                  </>
                )}
                <Row justify="start">
                  <Text
                    color={colorFromLevel(expand["Premium"] ? 1 : -1)}
                    bold
                    fontSize={16}
                    lHeight={"1rem"}
                    justStart
                  >
                    Total
                  </Text>
                </Row>
              </CustomCollapse>
            </Collapse>

            <Collapse isOpened={showRows.Surcharge}>
              <Row justify="start">
                <Link
                  color={colorFromLevel(expand["Surcharge"] ? 1 : -1)}
                  bold
                  fontSize={16}
                  lHeight={"1rem"}
                  justBetween
                  onClick={() => handleExpand("Surcharge")}
                >
                  Surcharges
                  <IconWrapper type={expand["Surcharge"] ? "up" : "down"} />
                </Link>
              </Row>
              <CustomCollapse level="1" isOpened={expand.Surcharge}>
                {isSurchargesAfter && (
                  <Row justify="start" category>
                    <Link
                      color={colorFromLevel(expand["Surcharges Before"] ? 2 : -1)}
                      bold
                      fontSize={16}
                      lHeight={"1rem"}
                      justBetween
                      onClick={() => handleExpand("Surcharges Before")}
                    >
                      Before Baby
                      <IconWrapper type={expand["Surcharges Before"] ? "up" : "down"} />
                    </Link>
                  </Row>
                )}
                <CustomCollapse level="2" isOpened={expand["Surcharges Before"] || !isSurchargesAfter}>
                  {companyA["Surcharges Before"] && (
                    <Row justify="start" category={!isSurchargesAfter} subCategory={isSurchargesAfter}>
                      <Text grey fontSize="16" bold>
                        {companyA.name}
                      </Text>
                    </Row>
                  )}
                  {companyB["Surcharges Before"] && (
                    <Row justify="start" category={!isSurchargesAfter} subCategory={isSurchargesAfter}>
                      <Text grey fontSize="16" bold>
                        {companyB.name}
                      </Text>
                    </Row>
                  )}
                  {isSurchargesAfter && (
                    <Row justify="start" category>
                      <Text
                        color={colorFromLevel(expand["Surcharges Before"] ? 2 : -1)}
                        bold
                        fontSize={16}
                        lHeight={"1rem"}
                        justStart
                      >
                        Total
                      </Text>
                    </Row>
                  )}
                </CustomCollapse>

                {isSurchargesAfter && (
                  <>
                    <Row justify="start" category>
                      <Link
                        color={colorFromLevel(expand["Surcharges After"] ? 2 : -1)}
                        bold
                        fontSize={16}
                        lHeight={"1rem"}
                        justBetween
                        onClick={() => handleExpand("Surcharges After")}
                      >
                        After Baby
                        <IconWrapper type={expand["Surcharges After"] ? "up" : "down"} />
                      </Link>
                    </Row>
                    <CustomCollapse level="2" isOpened={expand["Surcharges After"]}>
                      {companyA["Surcharges After"] && (
                        <Row justify="start" subCategory>
                          <Text grey fontSize="16" bold>
                            {companyA.name}
                          </Text>
                        </Row>
                      )}
                      {companyB["Surcharges After"] && (
                        <Row justify="start" subCategory>
                          <Text grey fontSize="16" bold>
                            {companyB.name}
                          </Text>
                        </Row>
                      )}
                      <Row justify="start" category>
                        <Text
                          color={colorFromLevel(expand["Surcharges After"] ? 2 : -1)}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justStart
                        >
                          Total
                        </Text>
                      </Row>
                    </CustomCollapse>
                  </>
                )}
                <Row justify="start">
                  <Text
                    color={colorFromLevel(expand["Surcharge"] ? 1 : -1)}
                    bold
                    fontSize={16}
                    lHeight={"1rem"}
                    justStart
                  >
                    Total
                  </Text>
                </Row>
              </CustomCollapse>
            </Collapse>

            <Collapse isOpened={showRows.Insurance}>
              <Row justify="start">
                <Link
                  color={colorFromLevel(expand["Insurance"] ? 1 : -1)}
                  bold
                  fontSize={16}
                  lHeight={"1rem"}
                  justBetween
                  onClick={() => handleExpand("Insurance")}
                >
                  In-Network Healthcare Charges
                  <IconWrapper type={expand["Insurance"] ? "up" : "down"} />
                </Link>
              </Row>
              <CustomCollapse level="1" isOpened={expand.Insurance}>
                {renderInsuranceSubItemTitles(companyA, "companyA", 2)}
                {renderInsuranceSubItemTitles(companyB, "companyB", 2)}
                <Row justify="start">
                  <Text
                    color={colorFromLevel(expand["Insurance"] ? 1 : -1)}
                    bold
                    fontSize={16}
                    lHeight={"1rem"}
                    justStart
                  >
                    Total
                  </Text>
                </Row>
              </CustomCollapse>
            </Collapse>
            <>
              <Collapse isOpened={showRows["Out-Of-Network"]}>
                <Row justify="start">
                  <Link
                    color={colorFromLevel(expand["Out-Of-Network"] ? 1 : -1)}
                    bold
                    fontSize={16}
                    lHeight={"1rem"}
                    justBetween
                    onClick={() => handleExpand("Out-Of-Network")}
                  >
                    Out-Of-Network Charges
                    <IconWrapper type={expand["Out-Of-Network"] ? "up" : "down"} />
                  </Link>
                </Row>
                <CustomCollapse level="1" isOpened={expand["Out-Of-Network"]}>
                  {renderSubItemTitles({
                    item: "Cost of Visits",
                    fontSize: "14",
                    category: true,
                  })}

                  {renderSubItemTitles({
                    item: "Reimbursement from Insurance",
                    fontSize: "14",
                    category: true,
                    isExpand: true,
                    level: 2,
                  })}
                  <CustomCollapse level="2" isOpened={expand["Reimbursement from Insurance"]}>
                    {showSubRows["Out-Of-Network"]["Negotiated Rate"] &&
                      renderSubItemTitles({
                        item: "Negotiated Rate",
                        fontSize: "16",
                        subCategory: true,
                      })}

                    {showSubRows["Out-Of-Network"]["Out-of-Pocket Accrual"] &&
                      renderSubItemTitles({
                        item: "Out-of-Pocket Accrual",
                        fontSize: "16",
                        subCategory: true,
                        isExpand: true,
                        level: 3,
                      })}
                    <CustomCollapse level="3" isOpened={expand["Out-of-Pocket Accrual"]}>
                      {showSubRows["Out-Of-Network"]["Deductibles"] &&
                        renderSubItemTitles({
                          item: "Deductibles",
                          fontSize: "10",
                          subCategory: true,
                        })}
                      {showSubRows["Out-Of-Network"]["Coinsurance"] &&
                        renderSubItemTitles({
                          item: "Coinsurance",
                          fontSize: "10",
                          subCategory: true,
                        })}
                      {showSubRows["Out-Of-Network"]["Out-of-Pocket Accrual"] &&
                        renderSubItemTitles({
                          item: "Out-of-Pocket Accrual",
                          fontSize: "16",
                          subCategory: true,
                          level: 3,
                          customTitle: "Total",
                          useCustomColor: true,
                        })}
                    </CustomCollapse>
                    {renderSubItemTitles({
                      item: "Reimbursement from Insurance",
                      fontSize: "14",
                      category: true,
                      level: 2,
                      customTitle: "Total",
                      useCustomColor: true,
                    })}
                  </CustomCollapse>
                  <Row justify="start">
                    <Text
                      color={colorFromLevel(expand["Out-Of-Network"] ? 1 : -1)}
                      bold
                      fontSize={16}
                      lHeight={"1rem"}
                      justStart
                    >
                      Total
                    </Text>
                  </Row>
                </CustomCollapse>
              </Collapse>

              <Collapse isOpened={showRows["Opt-Out Payment"]}>
                <Row justify="start">
                  <Link
                    color={colorFromLevel(expand["Opt-Out Payment"] ? 1 : -1)}
                    bold
                    fontSize={16}
                    lHeight={"1rem"}
                    justBetween
                    onClick={() => handleExpand("Opt-Out Payment")}
                  >
                    Opt-Out Payment
                    <IconWrapper type={expand["Opt-Out Payment"] ? "up" : "down"} />
                  </Link>
                </Row>
                <CustomCollapse level="1" isOpened={expand["Opt-Out Payment"]}>
                  {isOptOutPaymentAfter && (
                    <Row justify="start" category>
                      <Link
                        color={colorFromLevel(expand["Opt-Out Payment Before"] ? 2 : -1)}
                        bold
                        fontSize={16}
                        lHeight={"1rem"}
                        justBetween
                        onClick={() => handleExpand("Opt-Out Payment Before")}
                      >
                        Before Baby
                        <IconWrapper type={expand["Opt-Out Payment Before"] ? "up" : "down"} />
                      </Link>
                    </Row>
                  )}
                  <CustomCollapse level="2" isOpened={expand["Opt-Out Payment Before"] || !isOptOutPaymentAfter}>
                    {companyA["Opt-Out Payment Before"] && (
                      <Row justify="start" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
                        <Text grey fontSize="16" bold>
                          {companyA.name}
                        </Text>
                      </Row>
                    )}
                    {companyB["Opt-Out Payment Before"] && (
                      <Row justify="start" category={!isOptOutPaymentAfter} subCategory={isOptOutPaymentAfter}>
                        <Text grey fontSize="16" bold>
                          {companyB.name}
                        </Text>
                      </Row>
                    )}
                    {isOptOutPaymentAfter && (
                      <Row justify="start" category>
                        <Text
                          color={colorFromLevel(expand["Opt-Out Payment Before"] ? 2 : -1)}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justStart
                        >
                          Total
                        </Text>
                      </Row>
                    )}
                  </CustomCollapse>

                  {isOptOutPaymentAfter && (
                    <>
                      <Row justify="start" category>
                        <Link
                          color={colorFromLevel(expand["Opt-Out Payment After"] ? 2 : -1)}
                          bold
                          fontSize={16}
                          lHeight={"1rem"}
                          justBetween
                          onClick={() => handleExpand("Opt-Out Payment After")}
                        >
                          After Baby
                          <IconWrapper type={expand["Opt-Out Payment After"] ? "up" : "down"} />
                        </Link>
                      </Row>
                      <CustomCollapse level="2" isOpened={expand["Opt-Out Payment After"]}>
                        {companyA["Opt-Out Payment After"] && (
                          <Row justify="start" subCategory>
                            <Text grey fontSize="16" bold>
                              {companyA.name}
                            </Text>
                          </Row>
                        )}
                        {companyB["Opt-Out Payment After"] && (
                          <Row justify="start" subCategory>
                            <Text grey fontSize="16" bold>
                              {companyB.name}
                            </Text>
                          </Row>
                        )}
                        <Row justify="start" category>
                          <Text
                            color={colorFromLevel(expand["Opt-Out Payment After"] ? 2 : -1)}
                            bold
                            fontSize={16}
                            lHeight={"1rem"}
                            justStart
                          >
                            Total
                          </Text>
                        </Row>
                      </CustomCollapse>
                    </>
                  )}
                  <Row justify="start">
                    <Text
                      color={colorFromLevel(expand["Opt-Out Payment"] ? 1 : -1)}
                      bold
                      fontSize={16}
                      lHeight={"1rem"}
                      justStart
                    >
                      Total
                    </Text>
                  </Row>
                </CustomCollapse>
              </Collapse>

              <Collapse isOpened={showRows["HSA Before"]}>
                <Row justify="start" style={{ fontSize: "16px" }}>
                  HSA
                </Row>
              </Collapse>

              <Collapse isOpened={showRows["HRA Before"]}>
                <Row justify="start" style={{ fontSize: "16px" }}>
                  HRA
                </Row>
              </Collapse>
            </>

            <Collapse isOpened={showRows["Not Covered"]}>
              <Row justify="start">
                <Link
                  color={colorFromLevel(expand["Not Covered"] ? 1 : -1)}
                  bold
                  fontSize={16}
                  lHeight={"1rem"}
                  justBetween
                  onClick={() => handleExpand("Not Covered")}
                >
                  Not Covered
                  <IconWrapper type={expand["Not Covered"] ? "up" : "down"} />
                </Link>
              </Row>
              <CustomCollapse level="1" isOpened={expand["Not Covered"]}>
                {companyA["Not Covered"] &&
                  renderSubItemTitles({
                    item: companyA.name,
                    fontSize: "14",
                    category: true,
                  })}
                {companyB["Not Covered"] &&
                  renderSubItemTitles({
                    item: companyB.name,
                    fontSize: "14",
                    category: true,
                  })}
                <Row justify="start">
                  <Text
                    color={colorFromLevel(expand["Not Covered"] ? 1 : -1)}
                    bold
                    fontSize={16}
                    lHeight={"1rem"}
                    justStart
                  >
                    Total
                  </Text>
                </Row>
              </CustomCollapse>
            </Collapse>
          </Collapse>

          <Row justify="start" align="center" bgColor="#E8EEFA" padding={"4px 10px;"}>
            <Link bold blue textAlign justBetween fontSize={20} onClick={() => handleExpand("total")}>
              Estimated Grand Total
              <IconWrapper type={expand.total ? "up" : "down"} />
            </Link>
          </Row>

          <Row header justify="start" align="flex-start">
            <Link
              color={colorFromLevel(expand.leftover ? 0 : -1)}
              bold
              fontSize={16}
              lHeight={"1rem"}
              justBetween
              onClick={() => handleExpand("leftover")}
            >
              Remaining HSA/HRA breakdown
              <IconWrapper type={expand.leftover ? "up" : "down"} />
            </Link>
          </Row>

          <CustomCollapse level="1" isOpened={expand.leftover}>
            <Collapse isOpened={showRows["HSA After"]}>
              <Row justify="start" style={{ fontSize: "16px" }}>
                Remaining HSA
              </Row>
            </Collapse>

            <Collapse isOpened={showRows["HRA After"]}>
              <Row justify="start" style={{ fontSize: "16px" }}>
                Remaining HRA
              </Row>
            </Collapse>
          </CustomCollapse>
        </InnerWrapper>
        <CardsWrapper>
          <InnerWrapper card width="100%">
            {renderRisk(getSelectedReport(), options.primary)}
          </InnerWrapper>
          <Text black bold italic fontSize="20" margin="50px 10px">
            vs
          </Text>
          <InnerWrapper card width="100%">
            {renderRisk(getFirstCustomPreference(), "custom")}
          </InnerWrapper>
          <Text black bold italic fontSize="20" margin="50px 10px">
            vs
          </Text>
          <InnerWrapper card width="100%">
            {renderRisk(getSecondCustomPreference(), "custom2")}
          </InnerWrapper>
        </CardsWrapper>
      </FlexWrapper>
      <OptimizeModal
        report={report}
        birthParentPlans={birthParentPlans}
        partnerPlans={partnerPlans}
        show={customPrefrenceModal}
        close={() => setCustomPrefrenceModal(!customPrefrenceModal)}
        handleUpdate={updatePreference}
      />
    </Wrapper>
  );
};

export default WhatIf;
