import React from "react";
import {
  CalculateFormTemplate,
  FormText,
  FormSelect as Select,
  CalculateInsuranceBroader as InsuranceBroader,
} from "components";

import { strToBoolTrue, strToBoolFalse } from "services/utils";

const CalculateInsurancePlan = ({ plans, groups, insurance, change, plansBaby, plansNetwork }) => {
  const handleChange = ({ target: { name, value } }) => {
    change(name, value);
    if ((name === "insurancePlans" || name === "groups") && value === "Mine isn’t one of these")
      window.location.replace("/groups");
  };

  const handleChangeBool = ({ target: { name, value } }) => {
    if (strToBoolTrue(value)) change(name, true);
    else if (strToBoolFalse(value)) change(name, false);
  };

  const FullContent = () => (
    <>
      <Select
        text="Your Employer"
        newStyle
        selection={["Mine isn’t one of these", ...groups.data]}
        name="groups"
        isSearch
        handleChange={handleChange}
        value={groups.value}
      />
      {plans.isVisible && groups.value !== "Mine isn’t one of these" && (
        <Select
          text={`Your Plan`}
          newStyle
          isSearch
          selection={["Mine isn’t one of these", ...plans.data]}
          name="insurancePlans"
          handleChange={handleChange}
          value={plans.value}
        />
      )}
      {plansNetwork.isVisible && plans.value !== "Mine isn’t one of these" && (
        <>
          <Select
            text={`Your Network`}
            selection={plansNetwork.data}
            selectObj="_id"
            newStyle
            select="network"
            name="plansNetwork"
            handleChange={handleChange}
            value={plansNetwork.value}
          />

          {insurance.broaderNetwork && (
            <FormText small center text={`this network uses ${insurance.broaderNetwork} rates`} />
          )}
        </>
      )}
    </>
  );

  return (
    <CalculateFormTemplate
      height="md"
      title={["Insurance Plan Options", "Insurance Network Options"]}
      activeKey={1}
      noBorder
      headerHeight={"50px"}
      borderShadow
      hasTwoChildren
    >
      <>
        <FullContent />
        {plansNetwork.value && plansNetwork.isVisible && (
          <>
            <Select
              text="First Baby?"
              newStyle
              isBool
              name="firstBaby"
              //If this default value stop workin go to store/pla/defaultSaga.js and increase the delay
              handleChange={handleChangeBool}
              value={insurance.firstBaby}
              selection={["No", "Yes"]}
            />
            {(insurance.firstBaby || insurance.firstBaby === false) && (
              <Select
                text="Plan Your Baby Will Join?"
                selection={plansBaby}
                newStyle
                isBig
                selectObj="value"
                select="name"
                name="insurancePlanType"
                value={insurance.insurancePlanType}
                //If this default value stop workin go to store/pla/defaultSaga.js and increase the delay
                handleChange={handleChange}
              />
            )}
          </>
        )}
      </>
      <>
        <InsuranceBroader />
      </>
    </CalculateFormTemplate>
  );
};

export default React.memo(CalculateInsurancePlan);
