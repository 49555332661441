import "./polyfills";
import React from "react";
import ReactDOM from "react-dom";
import Circular from "fonts/Circular.woff";
import Circular2 from "fonts/Circular.woff2";
import { Provider } from "react-redux";
import { ConnectedRouter as Router } from "connected-react-router";
import { createGlobalStyle } from "styled-components";
import { IntlProvider } from "react-intl";
import App from "components/App";
import store from "./store/configure";
import history from "./store/history";
import "antd/dist/antd.css";
import HttpRedirect from "components/HttpRedirect";

const GlobalStyle = createGlobalStyle`
  html {
  scroll-behavior: smooth;
  }
  body {
    // min-width: 1308px;
    min-width: 100%;
    margin: 0;
    background-color: #fff;
    text-transform: none;
    font-family: circular !important;
    font-variant: none !important;
    font-feature-settings: normal !important;
    word-spacing: 0px !important;
  }

  @font-face {
    font-family: 'circular';
    src: local('circular'), local('circular'), url(${Circular2}) format('woff2'), url(${Circular}) format('woff');
  }

  #root {
    box-sizing: border-box;
    font-family: circular;
    font-size: 22px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5em;
    color: #1c355e;
  }

  // center navigation bar in optimizeUserPageV2
  .ant-tabs-nav-wrap {
    display: flex;
    justify-content: space-evenly;
  }

  @media (max-width: 800px) {
    .ant-modal-centered::before {
      height:30% !important;
    }
  }

  @media print {
    .ant-modal-wrap.ant-modal-centered {
      overflow: hidden;
      background: #fff
    }
  
    #root {
      display: none;
    }

  }
  @media (max-width: 580px) {
    .mobile-hide {
      display: none;
    }
  }
`;

ReactDOM.render(
  <Provider store={store}>
    <Router history={history}>
      <IntlProvider locale="en-US">
        <HttpRedirect>
          <GlobalStyle />
          <App />
        </HttpRedirect>
      </IntlProvider>
    </Router>
  </Provider>,
  document.getElementById("root"),
);
