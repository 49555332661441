// V3
// switch to include plans instead of exclude

import React, { useState } from "react";
import styled from "styled-components";

import {
  AntModalView as ModalView,
  FormSelect as Select,
  Table,
  TableHead,
  TableCell,
  TableRow,
  Text,
} from "components";
import { useSelector } from "react-redux";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
`;

const OptimizeModal = ({ report, birthParentPlans, partnerPlans, show, close, handleUpdate }) => {
  const {
    birthParent,
    partner,
    type,
    event: {
      general: { children },
      marriage: { considerSpousePlan },
    },
  } = report;
  const customPref = report.preferences[show] || {
    title: `Custom Coverage #${show === "custom" ? "1" : "2"}`,
    subTitle: "",
    apply: true,
    join: null,
    cost: "lowest",
    childrenJoin: null,
    include: {
      birthParentPlans: [],
      partnerPlans: [],
    },
    exclude: {
      birthParentPlans: [],
      partnerPlans: [],
    },
    lifeEvent: {
      name: null,
      date: null,
      allowSwitch: false,
    },
    bills: {
      inNetworkParent: 25285,
      hospitalVisits: 1,
    },
  };

  const [preferences, setPreferences] = useState(customPref);

  const hasKid = useSelector((state) => state.report.hasKid);

  const [selectPlan, setSelectPlan] = useState({
    birthParent: null,
    partner: null,
  });

  const handleChange = ({ target: { name, value } }) => {
    if (name.includes("plan")) {
      const fields = name.split(" ");
      const parent = fields[0];

      let include = preferences.include;
      if (parent === "birthParent") include.birthParentPlans = [value];
      if (parent === "partner") include.partnerPlans = [value];

      setPreferences({ ...preferences, include });
      setSelectPlan({ ...selectPlan, [parent]: value });
    } else {
      setPreferences({ ...preferences, [name]: value });
    }
  };

  const totalOptions = () => {
    const birthParentOptions = birthParent.planIds.length;
    const partnerOptions = partner.planIds.length;
    const sumOptions = birthParentOptions + partnerOptions;
    const multiplyOptions = birthParentOptions * partnerOptions;

    const totalOptions =
      type === "household" && considerSpousePlan && hasKid
        ? sumOptions + multiplyOptions * 2
        : type === "household" && considerSpousePlan
        ? sumOptions + multiplyOptions
        : sumOptions;

    return (
      <Text black fontSize="13">
        {"You have " + totalOptions + " options available to you."}
      </Text>
    );
  };

  const joinSelection = (type, birthParentCompany, partnerCompany) => {
    let selection = [];
    if (type === "household" && considerSpousePlan) selection.push({ value: "separate", display: "Separate Plans" });
    if (birthParentCompany) selection.push({ value: "birthParent", display: birthParentCompany });
    if (partnerCompany) selection.push({ value: "partner", display: partnerCompany });

    return selection;
  };

  const birthParentSelection = birthParentPlans.map((plan) => {
    const { externalId, network, name } = plan;
    return { value: externalId, display: `${network} ${report.version === "v1" ? name : ""}` };
  });

  const partnerSelection = partnerPlans.map((plan) => {
    const { externalId, network, name } = plan;
    return { value: externalId, display: `${network} ${report.version === "v1" ? name : ""}` };
  });

  const childrenSelection = [
    { display: birthParent.name || "You", value: "birthParent" },
    { display: partner.name || "Your Spouse", value: "partner" },
    { display: "Lowest", value: "lowest" },
  ];

  return (
    <ModalView
      isShow={show}
      close={close}
      textButton="Save"
      handleAction={() => {
        close();
        handleUpdate({ preferences, preferenceKey: show }).then(() => {
          setPreferences({
            ...preferences,
            join: null,
            childrenJoin: null,
            include: {
              birthParentPlans: [],
              partnerPlans: [],
            },
          });
          setSelectPlan({
            birthParent: null,
            partner: null,
          });
        });
      }}
      title="See A Specific Coverage Option"
    >
      <Wrapper>
        {totalOptions()}
        <br></br>
        <Table>
          <tbody>
            <TableRow>
              <TableHead>
                <Text black fontSize="13">
                  Which insurance provider are you considering?
                </Text>
              </TableHead>
              <TableCell width="300px">
                <Select
                  selection={joinSelection(type, birthParent.company, partner.company)}
                  selectObj="value"
                  select="display"
                  isSearch
                  name="join"
                  handleChange={handleChange}
                  value={preferences.join}
                  width="170px"
                />
              </TableCell>
            </TableRow>
            {(preferences.join === "birthParent" || preferences.join === "separate") && (
              <TableRow>
                <TableHead>
                  <Text black fontSize="13">
                    {"Select " + birthParent.company + " plan:"}
                  </Text>
                </TableHead>
                <TableCell width="300px">
                  <Select
                    selection={birthParentSelection}
                    selectObj="value"
                    select="display"
                    isSearch
                    name="birthParent plan"
                    handleChange={handleChange}
                    value={selectPlan.birthParent}
                    width="170px"
                  />
                </TableCell>
              </TableRow>
            )}
            {(preferences.join === "partner" || preferences.join === "separate") && (
              <TableRow>
                <TableHead>
                  <Text black fontSize="13">
                    {"Select " + partner.company + " plan:"}
                  </Text>
                </TableHead>
                <TableCell width="300px">
                  <Select
                    selection={partnerSelection}
                    selectObj="value"
                    select="display"
                    isSearch
                    name="partner plan"
                    handleChange={handleChange}
                    value={selectPlan.partner}
                    width="170px"
                  />
                </TableCell>
              </TableRow>
            )}
            {preferences.join === "separate" && hasKid ? (
              <TableRow>
                <TableHead>
                  <Text black fontSize="13">
                    {"Whose plan should children join?"}
                  </Text>
                </TableHead>
                <TableCell width="300px">
                  <Select
                    selection={childrenSelection}
                    selectObj="value"
                    select="display"
                    isSearch
                    name="childrenJoin"
                    handleChange={handleChange}
                    value={preferences.childrenJoin}
                    width="170px"
                  />
                </TableCell>
              </TableRow>
            ) : (
              <></>
            )}
          </tbody>
        </Table>
      </Wrapper>
    </ModalView>
  );
};

export default OptimizeModal;
