import { useEffect, useRef } from "react";
import { useFormikContext } from "formik";
import isEqual from "react-fast-compare";
import { useDebouncedCallback } from "use-debounce";
import initialValues from "./initialValues";

const FormikPersist = ({ name, canCallOnSave, isMasterReport }) => {
  const { values, setValues } = useFormikContext();
  const prefValuesRef = useRef();
  const { reportId } = values;

  const onSave = (values) => {
    let serviceList = [];
    if (isMasterReport) {
      serviceList = [
        "Inpatient Hospital Stay",
        "Primary Care Sick Visit",
        "Specialist Visit",
        "Labwork",
        "Outpatient Surgery Physician Fees",
        "Urgent Care",
        "Therapy",
        "Rehabilitation Services (PT, OT, Chiropractor)",
        "Fertility Services (IVF, Egg Freezing)",
      ];
    } else {
      serviceList = [
        ...new Set(
          values.expectedTable.map((element) => element.billedAs).filter((element) => element !== "Preventive Care"),
        ),
      ];
    }
    if (reportId === "3EPw03KGJ" || reportId === "z-JQ_gkpv" || reportId === "HPt0VFqjx") {
      serviceList.splice(7, 1, "Brand Name Drug");
    }
    values.serviceList = serviceList;
    window.localStorage.setItem(name, JSON.stringify(values));
  };

  const debouncedOnSave = useDebouncedCallback(onSave, 300);

  useEffect(() => {
    const savedForm = window.localStorage.getItem(name);

    if (savedForm && canCallOnSave) {
      const parsedForm = JSON.parse(savedForm);
      prefValuesRef.current = parsedForm;
      setValues(parsedForm);
    }
  }, [canCallOnSave]);

  useEffect(() => {
    if (canCallOnSave && !isEqual(prefValuesRef.current, values)) {
      debouncedOnSave(values);
    }
  });

  useEffect(() => {
    if (canCallOnSave) {
      prefValuesRef.current = values;
    }
  });

  return null;
};

export default FormikPersist;
