import React, { useState } from "react";
import styled from "styled-components";
import { DownOutlined } from "@ant-design/icons";

const SelectContainer = styled.div`
  position: relative;
  margin: 0;
  z-index: ${({ zIndex }) => zIndex || 1};
`;

const SelectLabelButton = styled.button`
  width: 100%;
  background-color: transparent;
  padding: 16px;
  color: #21355b;
  border: 2px solid #d0d5dd;
  border-radius: 8px;
  display: flex;
  align-items: center;
  :hover {
    cursor: pointer;
  }

  .label {
    flex-grow: 1;
    justify-content: start;
    line-height: normal;
    text-align: left;
    font-weight: 700;
  }

  .icon {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20px;
    width: 20px;
    max-width: 20px;
    max-height: 20px;
    ${(p) => `${p.isVisible ? "" : "transform: rotate(180deg);"}`}
  }
`;

const DropdownStyle = styled.div`
  position: absolute;
  width: 100%;
  top: 60px;
  left: 0;
  padding: 0;
  display: flex;
  flex-direction: column;
  border-radius: 5px;
  background: #d9f3f1;
  border: 2px solid #d0d5dd;
  transition: max-height 0.2s ease;
  overflow-x: hidden;
  ${(p) => `${p.withScroll ? "max-height: 200px; overflow-y: scroll;" : "overflow-y: hidden;"}`}
  ${(p) => `${!p.isVisible ? "visibility: hidden;" : ""}`}
`;

const DropdownItem = styled.div`
  width: 100%;
  padding: 16px;
  color: #21355b;
  display: flex;
  align-items: center;
  font-weight: 500;
  cursor: pointer;
  ${(p) => `${p.active ? "font-weight: 700;" : ""}`}
  &:hover, :focus, :focus:hover {
    background-color: #166edc;
    color: #fafafa;
    outline: none;
  }
`;

export default function CustomSelect({ label, value, values, onChange, zIndex = 1 }) {
  const [currentValue, setCurrentValue] = useState(value);
  const [open, setOpen] = useState(false);

  const handleChange = (value) => {
    setCurrentValue(value);
    if (onChange) {
      onChange(value);
    }
    setOpen(false);
  };

  return (
    <SelectContainer zIndex={zIndex}>
      <SelectLabelButton onClick={() => setOpen((prev) => !prev)} isVisible={open}>
        <span className="label">{values.find((thisValue) => thisValue.value === value)?.label || label}</span>
        <span className="icon">
          <DownOutlined />
        </span>
      </SelectLabelButton>
      <DropdownStyle isVisible={open} withScroll={false}>
        {values.map((value, index) => (
          <DropdownItem onClick={() => handleChange(value.value)} active={value.value === currentValue} key={index}>
            {value.label}
          </DropdownItem>
        ))}
      </DropdownStyle>
    </SelectContainer>
  );
}
