import React, { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import SegmentedControl from "../SegmentedControl";
import styled from "styled-components";

const ComparisonModeSwitch = () => {
  const dispatch = useDispatch();
  const InnerWrapper = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    h1 {
      font-size: 18px;
      line-height: 18px;
      font-weight: bolder;
      margin-bottom: 0;
    }
  `;

  const value = useSelector((state) => state.report.comparisonMode) || "off";

  return (
    <InnerWrapper>
      <h1>Comparison mode</h1>
      <SegmentedControl
        size="small"
        name="group-comparison"
        initialValue={value}
        callback={(val) => {
          dispatch(Actions.Creators.switchState({ comparisonMode: val }));
        }}
        controlRef={useRef()}
        segments={[
          {
            label: "On",
            value: "on",
            ref: useRef(),
          },
          {
            label: "Off",
            value: "off",
            ref: useRef(),
          },
        ]}
      />
    </InnerWrapper>
  );
};

export default ComparisonModeSwitch;
