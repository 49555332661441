import { Menu, Modal, Empty, Button as ButtonModal } from "antd";
import "./styles.css";
import React from "react";
import styled from "styled-components";
import { isEmpty } from "utils/object";
import { HSABenefitFilter } from "./sections/HSABenefitFilter";
import { ExpectedServicesFilter } from "./sections/ExpectedServicesFilter";
import { PlansToJoinFilter } from "./sections/PlansToJoinFilter";
import { DoctorOptionsFilter } from "./sections/DoctorOptionsFilter";
import { DrugOptionsFilter } from "./sections/DrugOptionsFilter";
import { HospitalOptionsFilter } from "./sections/HospitalOptionsFilter";
import { InsuranceNetworksFilter } from "./sections/InsuranceNetworksFilter";
import { CategoriesFilter } from "./sections/CategoriesFilter";
import { PlansFilter } from "./sections/PlansFilter";
import { FilterContextProvider, useFilterContext } from "./FilterContext";

export const USDollar = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  maximumFractionDigits: 0,
});

export const CustomRadioCheckGroup = styled.ul`
  li {
    display: flex;
    width: 100%;
    padding: 6px;
    border: 2px solid #d0d5dd;
    color: #21355b;
    border-radius: 8px;
    margin: 8px 0;
    font-weight: 500;

    label {
      display: flex;
      width: 100%;
      cursor: pointer;

      .radio-plans-label,
      .checkbox-plans-label {
        line-height: initial;
        margin: 6px;
      }
    }

    .check-input-radio-wrapper,
    .check-input-checkbox-wrapper {
      position: relative;
      background-color: transparent;
      border-radius: 100%;
      border: 2px solid #21355b;
      height: 20px;
      width: 20px;
      min-width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 6px;

      &.checked {
        background-color: #21355b;

        .check-icon-radio,
        .check-icon-checkbox {
          display: inline;
          filter: invert(1);
        }
      }

      input {
        display: none;
      }

      .check-icon-radio,
      .check-icon-checkbox {
        display: none;
        top: 0;
        width: max-content;
        height: max-content;
      }
    }

    .check-input-checkbox-wrapper {
      border-radius: 4px;
    }

    &.group-no-border {
      border: none;
      padding: 0;

      .check-input-radio-wrapper {
        border-radius: 4px;
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;
`;

const CustomMenu = styled(Menu)`
  padding: 30px 0px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  width: 100%;

  font-variant: none !important;
  font-feature-settings: normal !important;
  word-spacing: 0px !important;
`;

export const ItensWrapper = styled.div``;

export const ItensWrapperHeading = styled.h3`
  font-weight: bold;
  color: #21355b;
  margin-bottom: 10px;
  line-height: initial;
`;

const StylesModal = styled(Modal)`
  font-family: circular;

  .ant-modal-close-x {
    font-size: 24px;
    margin-right: 0;
    margin-top: 0;
  }
  .ant-modal-body {
    padding-bottom: 24px;
  }
  .ant-modal-header {
    border-bottom: 0 none;
  }
  .ant-modal-footer {
    border-top: 0 none;
  }
`;

const ButtonsContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: 10px;
`;

const UpdateButton = styled.button`
  border: 2px solid ${(props) => (props.isDisabled ? "#D0D5DD" : "#21355B")};
  background: ${(props) => (props.isDisabled ? "#D0D5DD" : "#21355B")};
  color: #fff;
  border-radius: 5px;
  font-size: 24px;
  padding: 0px 36px;
  cursor: pointer;
  line-height: normal;
  // margin-bottom: 10px;
`;

const CancelButton = styled.button`
  border: 2px solid #d0d5dd;
  background: transparent;
  color: #21355b;
  border-radius: 5px;
  font-size: 16px;
  padding: 0px 24px;
  cursor: pointer;
  line-height: normal;
`;

const BigHeading = styled.h2`
  color: #21355b;
  text-transform: uppercase;
  margin-top: 10px;
  font-weight: bold;
  font-size: 18px;
`;

const FilterV2 = ({ report }) => {
  const {
    doctorOptions,
    drugOptions,
    hospitalOptions,
    broaderNetworksOptions,
    filteredProvidersNotInNetwork,
    setFilteredProvidersNotInNetwork,
    renderFilteredProvidersNotInNetwork,
    handleApply,
    isDisabled,
    handleOtherValueChange,
    handleHsaBalanceReturn,
    handleHsaPeriod,
    visible,
    setVisible,
    noHsaOptionsModal,
    setNoHsaOptionsModal,
    hsaHraImpact,
    hsaContribution,
    hsaContributionOtherValue,
    hsaBalanceInvest,
    hsaBalanceReturn,
    hsaBalanceTimePeriod,
    taxBracket,
    selectedCategoriesOptions,
    selectedPlansOptions,
    applyLoading,
    setApplyLoading,
    closeMenu,
  } = useFilterContext();

  if (!report) return null;

  return (
    <Wrapper className="filter">
      <CustomMenu onClick={() => {}} defaultOpenKeys={["sub6", "sub9", "sub10", "sub12"]} mode="inline" multiple>
        <BigHeading>Sort By</BigHeading>

        <ExpectedServicesFilter report={report} />

        <HSABenefitFilter
          report={report}
          hsaHraImpact={hsaHraImpact}
          hsaContribution={hsaContribution}
          hsaContributionOtherValue={hsaContributionOtherValue}
          hsaBalanceInvest={hsaBalanceInvest}
          hsaBalanceReturn={hsaBalanceReturn}
          hsaBalanceTimePeriod={hsaBalanceTimePeriod}
          taxBracket={taxBracket}
          handleOtherValueChange={handleOtherValueChange}
          handleHsaBalanceReturn={handleHsaBalanceReturn}
          handleHsaPeriod={handleHsaPeriod}
        />

        <BigHeading>Filter By</BigHeading>

        <PlansToJoinFilter report={report} />

        <DoctorOptionsFilter doctorOptions={doctorOptions} />

        <DrugOptionsFilter drugOptions={drugOptions} />

        <HospitalOptionsFilter hospitalOptions={hospitalOptions} />

        <InsuranceNetworksFilter broaderNetworksOptions={broaderNetworksOptions} />

        <CategoriesFilter categoriesOptions={selectedCategoriesOptions} />

        <PlansFilter plansOptions={selectedPlansOptions} />

        <ButtonsContainer>
          <UpdateButton
            id="filter-sidebar-update-btn"
            isDisabled={isDisabled}
            disabled={isDisabled}
            onClick={() => {
              setApplyLoading(true);
              handleApply();
            }}
          >
            {`${applyLoading ? "Updating" : "Update"}`}
          </UpdateButton>

          <CancelButton onClick={closeMenu}>Cancel</CancelButton>
        </ButtonsContainer>
      </CustomMenu>

      <StylesModal
        title={
          !isEmpty(filteredProvidersNotInNetwork)
            ? "Try clearing some of your filters to get a recommendation"
            : "No recommendation matching your filters"
        }
        visible={visible}
        footer={[
          <ButtonModal
            key="submit"
            type="primary"
            onClick={() => {
              setVisible(false);
              setFilteredProvidersNotInNetwork({});
            }}
          >
            Ok
          </ButtonModal>,
        ]}
        onCancel={() => {
          setVisible(false);
          setFilteredProvidersNotInNetwork({});
        }}
      >
        {!isEmpty(filteredProvidersNotInNetwork) && visible ? (
          renderFilteredProvidersNotInNetwork(filteredProvidersNotInNetwork)
        ) : (
          <Empty description={<span>Try clearing your filters to see a recommendation.</span>} />
        )}
      </StylesModal>

      <StylesModal
        title={"No HSA options available"}
        visible={noHsaOptionsModal}
        onOk={() => {
          setNoHsaOptionsModal(false);
          if (report.preferences["1"].hsaHraImpact === "spending-hsa-hra") {
            setApplyLoading(true);
            handleApply();
          }
        }}
        onCancel={() => {
          setNoHsaOptionsModal(false);
          if (report.preferences["1"].hsaHraImpact === "spending-hsa-hra") {
            setApplyLoading(true);
            handleApply();
          }
        }}
        cancelButtonProps={{ style: { display: "none" } }}
      >
        <Empty
          description={
            <>
              <span>You don&apos;t have any HSA options available.</span>
              <span>Changing Tax and Investment Assumptions back to Healthcare Spending Only</span>
            </>
          }
        />
      </StylesModal>
    </Wrapper>
  );
};

export default FilterV2;
