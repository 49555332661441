import { List } from "antd";
import "./styles.css";
import React, { createContext, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { createNewPreference, getAllMyOptions, getPlansArr, optimizeV2 } from "services/api";
import { getToken } from "services/utils";
import { insuranceNetworkDisplayNameMap } from "enums/insuranceNetworkDisplayNameMap.enums";

const FilterContext = createContext({});

const SELECT_ALL_VALUE = "Select All";
const SELECT_NONE_VALUE = "Select None";

export const FilterContextProvider = ({
  providersList,
  report,
  plans,
  updateState,
  updateAllMyOptions,
  broaderNetworksFromAllMyOptions,
  categories,
  indexToSplit,
  throwForceUpdateState,
  isMenuOpen,
  closeMenu,
  children,
}) => {
  const [filterUpdate, setFilterUpdate] = useState(0);
  const [applyLoading, setApplyLoading] = useState(false);
  const [canUpdate, setCanUpdate] = useState(false);

  const [doctors, setDoctors] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [servicesOptions, setServicesOptions] = useState(false);
  const [hospitals, setHospitals] = useState([]);
  const [broaderNetworks, setBroaderNetworks] = useState([]);
  const [hsaHraImpacts, setHsaHraImpacts] = useState("spending-only");
  const [hsaContributions, setHsaContributions] = useState("max");
  const [hsaContributionOtherValues, setHsaContributionOtherValues] = useState(500);
  const [taxBrackets, setTaxBrackets] = useState(37);
  const [hsaBalanceInvests, setHsaBalanceInvests] = useState(true);
  const [hsaBalanceReturns, setHsaBalanceReturns] = useState(10);
  const [hsaBalanceTimePeriods, setHsaBalanceTimePeriods] = useState(1);
  const [previousHsaHraImpactValues, setPreviousHsaHraImpactValues] = useState({
    hsaHraImpacts,
    hsaContributions,
    hsaContributionOtherValues,
    taxBrackets,
    hsaBalanceInvests,
    hsaBalanceReturns,
    hsaBalanceTimePeriods,
  });
  const [previousServiceOption, setPreviousServiceOption] = useState(servicesOptions);

  const handleOtherValueChange = (value) => {
    dispatch(
      Actions.Creators.switchState({
        hsaContributionOtherValue: value,
      }),
    );
  };

  const isNotNullOrUndefined = (value) => {
    return value !== "" && value !== undefined;
  };

  const handleHsaBalanceReturn = (value) => {
    dispatch(
      Actions.Creators.switchState({
        hsaBalanceReturn: isNotNullOrUndefined(value) && value < -100 ? -100 : value,
      }),
    );
  };

  const handleHsaPeriod = (value) => {
    dispatch(
      Actions.Creators.switchState({
        hsaBalanceTimePeriod: isNotNullOrUndefined(value) && value < 0 ? 0 : value,
      }),
    );
  };

  const [doctorOptions, setDoctorOptions] = useState([]);
  const [drugOptions, setDrugOptions] = useState([]);
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [broaderNetworksOptions, setBroaderNetworksOptions] = useState([]);
  const [filteredProvidersNotInNetwork, setFilteredProvidersNotInNetwork] = useState({});

  const formatSelectedPlansList = (plans) => {
    return plans
      .map((plan) => {
        return {
          value: plan.id,
          label: plan.group.split(" ")[0] + " " + plan.network,
        };
      })
      .sort((a, b) => a.label.localeCompare(b.label));
  };

  const formatSelectedCategoriesList = (categories) => {
    return [...new Set(categories.map((category) => category.name))];
  };

  const [selectedPlansOptions] = useState(formatSelectedPlansList(plans));
  const [selectedCategoriesOptions] = useState(formatSelectedCategoriesList(categories));

  const [selectedPlans, setSelectedPlans] = useState([]);
  const [selectedCategories, setSelectedCategories] = useState([]);

  const [hasSeparateFilters, setHasSeparateFilters] = useState(false);
  const [separateFilterValues, setSeparateFilterValues] = useState(2000);

  const [visible, setVisible] = useState(false);
  const [noHsaOptionsModal, setNoHsaOptionsModal] = useState(false);

  const doctor = useSelector((state) => state.report.doctor);
  const hospital = useSelector((state) => state.report.hospital);
  const drug = useSelector((state) => state.report.drug);
  const serviceOption = useSelector((state) => state.report.serviceOption);
  const selectedPlan = useSelector((state) => state.report.selectedPlan);
  const selectedCategory = useSelector((state) => state.report.selectedCategory);
  const hasSeparateFilter = useSelector((state) => state.report.hasSeparateFilter);
  const separateFilterValue = useSelector((state) => state.report.separateFilterValue);
  const hsaHraImpact = useSelector((state) => state.report.hsaHraImpact);
  const hsaContribution = useSelector((state) => state.report.hsaContribution);
  const hsaContributionOtherValue = useSelector((state) => state.report.hsaContributionOtherValue);
  const taxBracket = useSelector((state) => state.report.taxBracket);
  const hsaBalanceInvest = useSelector((state) => state.report.hsaBalanceInvest);
  const hsaBalanceReturn = useSelector((state) => state.report.hsaBalanceReturn);
  const hsaBalanceTimePeriod = useSelector((state) => state.report.hsaBalanceTimePeriod);

  const reportJoin = report.preferences["1"].join;

  const [previousReportJoin, setPreviousReportJoin] = useState(reportJoin);

  const broaderNetworkIncludes = useSelector((state) => state.report.broaderNetworkIncludes);

  const plansToJoin = useSelector((state) => state.report.plansToJoin);

  const broaderNetworkIncludesOnLoad = useSelector((state) => state.report.broaderNetworkIncludesOnLoad);

  const broaderNetworkPlans = useSelector((state) => state.report.broaderNetworkPlans);

  const dispatch = useDispatch();

  const handleFilteredProvidersNotInNetwork = (filteredProviders) => {
    let isEmpty = true;
    Object.values(filteredProviders).map((item) => {
      if (item.length) isEmpty = false;
    });
    if (!isEmpty) {
      setFilteredProvidersNotInNetwork(filteredProviders);
      setVisible(true);
    }
  };

  const renderFilteredProvidersNotInNetwork = (filteredProviders) => {
    const data = filteredProviders ? Object.entries(filteredProviders) : [];
    return (
      <List
        size="small"
        // header={<div>Header</div>}
        // footer={<div>Footer</div>}
        dataSource={data}
        renderItem={(item) =>
          !!item[1].length && (
            <List.Item>
              {item[0]} is not in-network with {item[1].join(", ")}.
            </List.Item>
          )
        }
      />
    );
  };

  const updateCustomPreference = async (
    hsaHraImpactValuesChanged,
    newCustomPreferenceOne,
    newCustomPreferenceTwo,
    token,
  ) => {
    const { custom, custom2 } = report.preferences;
    if (custom?.apply) {
      const resCustomOne = await createNewPreference(report._id, "custom", newCustomPreferenceOne, token);
      if (resCustomOne.status === 200) {
        const opt = await optimizeV2({
          reportId: report._id,
          preferenceKey: "custom",
          onlyExpected: serviceOption,
          shouldFilter: false,
        });
        opt.status === 200 &&
          dispatch(
            Actions.Creators.switchState({
              report: opt.data.report,
              customPreferenceOne: opt.data.results[0]?.selected,
            }),
          );
      }
    }
    if (custom2?.apply) {
      const resCustomTwo = await createNewPreference(report._id, "custom2", newCustomPreferenceTwo, token);
      if (resCustomTwo.status === 200) {
        const opt = await optimizeV2({
          reportId: report._id,
          preferenceKey: "custom2",
          onlyExpected: serviceOption,
          shouldFilter: false,
        });
        opt.status === 200 &&
          dispatch(
            Actions.Creators.switchState({
              report: opt.data.report,
              customPreferenceTwo: opt.data.results[0]?.selected,
            }),
          );
      }
    }
  };

  const handleApply = async () => {
    const filterDoctors = doctor?.map((item) => {
      return { name: item, type: "doctor" };
    });
    const filterHospitals = hospital?.map((item) => {
      return { name: item, type: "hospital" };
    });
    const drugDoctors = drug?.map((item) => {
      return { name: item, type: "drug" };
    });

    let filteredBroaderNetworkIncludes = broaderNetworkIncludes;
    const filteredProviders = {};

    broaderNetworksFromAllMyOptions.map((network) => {
      const { providers, broaderNetwork } = network;
      const { doctor: doctors, drug: drugs, hospital: hospitals } = providers;
      const drug = [];
      let hospitalFiltered = !hospital.every((i) => hospitals.includes(i));
      let drugFiltered = !drug.every((i) => drugs.includes(i));
      let doctorFiltered = !doctor.every((i) => doctors.includes(i));
      const isFilteredRow = hospitalFiltered || drugFiltered || doctorFiltered;

      if (isFilteredRow) {
        filteredBroaderNetworkIncludes = filteredBroaderNetworkIncludes.filter((e) => e !== broaderNetwork);

        const hospitalNames = hospital.filter((x) => !hospitals.includes(x));
        const drugNames = drug.filter((x) => !drugs.includes(x));
        const doctorNames = doctor.filter((x) => !doctors.includes(x));
        const names = [...hospitalNames, ...drugNames, ...doctorNames];
        names.map((item) => {
          filteredProviders[item] = filteredProviders[item] || [];
        });

        let difference = broaderNetworkIncludes?.filter((x) => !broaderNetworkIncludesOnLoad.includes(x));

        difference.includes(broaderNetwork) &&
          names.length > 0 &&
          names.map((item) => {
            filteredProviders[item] = [...filteredProviders[item], broaderNetwork];
          });
      }

      isFilteredRow &&
        dispatch(
          Actions.Creators.switchState({
            broaderNetworkIncludes: filteredBroaderNetworkIncludes,
          }),
        );
    });

    handleFilteredProvidersNotInNetwork(filteredProviders);

    const include = {
      birthParentPlans: [],
      partnerPlans: [],
    };
    filteredBroaderNetworkIncludes.map((item) => {
      include.birthParentPlans = [...include.birthParentPlans, ...broaderNetworkPlans[item]["birthParentPlans"]];
      include.partnerPlans = [...include.partnerPlans, ...broaderNetworkPlans[item]["partnerPlans"]];
    });
    const newPreference = {
      ...report.preferences["1"],
      filterProviders: [...filterHospitals, ...drugDoctors, ...filterDoctors],
      join: plansToJoin,
      include,
      selectedPlans: selectedPlan.filter((plan) => plan !== SELECT_ALL_VALUE && plan !== SELECT_NONE_VALUE),
      selectedCategories: selectedCategory.filter(
        (category) => category !== SELECT_ALL_VALUE && category !== SELECT_NONE_VALUE,
      ),
      onlyExpected: serviceOption,
      hasSeparateFilter,
      separateFilterValue,
      hsaHraImpact,
      hsaContribution,
      hsaContributionOtherValue,
      taxBracket,
      hsaBalanceInvest,
      hsaBalanceReturn,
      hsaBalanceTimePeriod,
    };

    const token = await getToken();
    const res = await createNewPreference(report._id, "1", newPreference, token);

    const newCustomPreferenceOne = {
      ...report.preferences["custom"],
      hsaHraImpact,
      hsaContribution,
      hsaContributionOtherValue,
      taxBracket,
      hsaBalanceInvest,
      hsaBalanceReturn,
      hsaBalanceTimePeriod,
    };

    const newCustomPreferenceTwo = {
      ...report.preferences["custom2"],
      hsaHraImpact,
      hsaContribution,
      hsaContributionOtherValue,
      taxBracket,
      hsaBalanceInvest,
      hsaBalanceReturn,
      hsaBalanceTimePeriod,
    };

    const hsaHraImpactValuesChanged =
      report.preferences["1"].hsaContribution !== hsaContribution ||
      report.preferences["1"].hsaContributionOtherValue !== hsaContributionOtherValue ||
      report.preferences["1"].taxBracket !== taxBracket ||
      report.preferences["1"].hsaBalanceInvest !== hsaBalanceInvest ||
      report.preferences["1"].hsaBalanceReturn !== hsaBalanceReturn ||
      report.preferences["1"].hsaBalanceTimePeriod !== hsaBalanceTimePeriod;

    if (res.status === 200) {
      try {
        const opt = await optimizeV2({
          reportId: report._id,
          preferenceKey: 1,
          onlyExpected: serviceOption,
          shouldRecalculate: hsaHraImpactValuesChanged,
        });
        if (opt.status === 204) {
          setNoHsaOptionsModal(true);
          dispatch(
            Actions.Creators.switchState({
              hsaHraImpact: "spending-only",
            }),
          );
          await createNewPreference(report._id, "1", report.preferences["1"], token);
          setApplyLoading(false);
          return;
        }
        updateState(opt);
        throwForceUpdateState();
        const resAllMyOptions = await getAllMyOptions(report._id, token, serviceOption);
        updateAllMyOptions(resAllMyOptions);

        const optimization = opt.data.filteredResults[0].selected;

        dispatch(Actions.Creators.loadRecommendedByOptmization({ ...optimization, version: report.version }));

        const { birthParent, partner } = optimization.optimizationDetails;
        const {
          broaderNetworks,
          providersGrid,
          event,
          isDue,
          partner: partnerFromReport,
          birthParent: birthParentFromReport,
        } = report;
        const { birthParentPlans, partnerPlans } = include;

        const plansIds = [birthParent.externalId, partner.externalId].filter((el) => Boolean(el));
        const data = await getPlansArr(plansIds, token);

        dispatch(
          Actions.Creators.loadRecommendedByReport({
            data,
            broaderNetworks,
            providersGrid,
            optimization: optimization,
            event,
            isDue,
            broaderNetworksFromAllMyOptions,
            plansFromPartner: partnerFromReport.planIds,
            plansFromBirthParent: birthParentFromReport.planIds,
            preferenceBirthParentPlans: birthParentPlans,
            preferencePartnerPlans: partnerPlans,
            indexToSplit,
            join: plansToJoin,
          }),
        );
        dispatch(
          Actions.Creators.switchState({
            appliedDoctor: doctor,
          }),
        );

        dispatch(
          Actions.Creators.switchState({
            appliedDrug: isDrugDefault,
          }),
        );

        dispatch(
          Actions.Creators.switchState({
            appliedHospital: hospital,
          }),
        );

        setFilterUpdate(filterUpdate + 1);
        onClearAll(doctor, hospital, drug, filteredBroaderNetworkIncludes, serviceOption);
      } catch (error) {
        setVisible(true);
        onClearAll(doctors, hospitals, drugs, broaderNetworks, serviceOption);
        dispatch(
          Actions.Creators.switchState({
            plansToJoin: reportJoin,
          }),
        );
        await createNewPreference(report._id, "1", report.preferences["1"], token);
      }
    }
    updateCustomPreference(hsaHraImpactValuesChanged, newCustomPreferenceOne, newCustomPreferenceTwo, token);
    setApplyLoading(false);
  };

  const isDoctorDefault = doctor.sort().toString() == doctors.sort().toString();
  const isServiceOptionDefault = serviceOption === servicesOptions;
  const isHospitalDefault = hospital.sort().toString() == hospitals.sort().toString();
  const isDrugDefault = drug.sort().toString() == drugs.sort().toString();
  const isBroaderNetworkDefault = broaderNetworks.sort().toString() == broaderNetworkIncludes.sort().toString();
  const isSelectedPlanDefault =
    selectedPlans
      .map((plan) => plan.value || plan)
      .sort()
      .toString() == selectedPlan.sort().toString();
  const isSelectedCategoryDefault = selectedCategories.sort().toString() == selectedCategory.sort().toString();
  const isHasSeparateFilterDefault = hasSeparateFilter === hasSeparateFilters;
  const isSeparateFilterValueDefault = separateFilterValue === separateFilterValues;
  const isHsaHraImpactsDefault = hsaHraImpacts === hsaHraImpact;
  const isHsaContributionsDefault = hsaContributions === hsaContribution;
  const isHsaContributionOtherValuesDefault = hsaContributionOtherValues === hsaContributionOtherValue;
  const isTaxBracketsDefault = taxBrackets === taxBracket;
  const isHsaBalanceInvestsDefault = hsaBalanceInvests === hsaBalanceInvest;
  const isHsaBalanceReturnsDefault = hsaBalanceReturns === hsaBalanceReturn;
  const isHsaBalanceTimePeriodsDefault = hsaBalanceTimePeriods === hsaBalanceTimePeriod;

  const isDisabled =
    (isDoctorDefault &&
      isHospitalDefault &&
      isDrugDefault &&
      (isBroaderNetworkDefault || (broaderNetworks && broaderNetworks.length === 0)) &&
      isServiceOptionDefault &&
      isSelectedPlanDefault &&
      isSelectedCategoryDefault &&
      isHasSeparateFilterDefault &&
      isSeparateFilterValueDefault &&
      isHsaHraImpactsDefault &&
      isHsaContributionsDefault &&
      isHsaContributionOtherValuesDefault &&
      isTaxBracketsDefault &&
      isHsaBalanceInvestsDefault &&
      isHsaBalanceReturnsDefault &&
      isHsaBalanceTimePeriodsDefault &&
      reportJoin === plansToJoin) ||
    !selectedCategory.filter((category) => category !== SELECT_NONE_VALUE).length ||
    !selectedPlan.filter((plan) => plan !== SELECT_NONE_VALUE).length;
  // || applyDisabled;

  useEffect(() => {
    setCanUpdate(!isDisabled);
  }, [isDisabled]);

  useEffect(() => {
    let doctorsList = [];
    let drugsList = [];
    let hospitalsList = [];
    providersList.map((item) => {
      switch (item.type) {
        case "Hospital":
          hospitalsList.push(item.name);
          break;
        case "Drug":
          drugsList.push(item.name);
          break;
        case "Doctor":
          doctorsList.push(item.name);
          break;
        default:
          break;
      }
    });

    const broaderNetworkCount = {};
    const broaderNetworksList = report.broaderNetworks.map((item) => {
      let { name, insuranceCompany } = item;
      if (insuranceCompany) {
        const insuranceCompanyDisplayName = insuranceNetworkDisplayNameMap[insuranceCompany] || insuranceCompany;
        return broaderNetworkCount[insuranceCompany] === 1
          ? { value: name, label: insuranceCompanyDisplayName }
          : { value: name, label: `${insuranceCompanyDisplayName} ${name}` };
      }
      return { value: name, label: name };
    });

    setDoctorOptions(doctorsList);
    setDrugOptions(drugsList);
    setHospitalOptions(hospitalsList);
    setBroaderNetworksOptions(broaderNetworksList);
  }, [providersList]);

  useEffect(() => {
    resetFilters();
  }, [report]);

  useEffect(() => {
    if (!isMenuOpen) resetFilters();
  }, [isMenuOpen]);

  const resetFilters = () => {
    let doctorsList = [];
    let drugsList = [];
    let hospitalsList = [];
    const filterProviders = report.preferences[1]?.filterProviders;

    const hasSeparateFilterReport = report.preferences[1]?.hasSeparateFilter || false;
    const separateFilterValueReport = report.preferences[1]?.separateFilterValue || 2000;

    const currentPreference = report?.preferences?.[1];

    const preferencePlans =
      (currentPreference?.selectedPlans.length && currentPreference.selectedPlans) ||
      selectedPlansOptions.map((plan) => plan.value);

    const preferenceCategories =
      (currentPreference?.selectedCategories.length && currentPreference.selectedCategories) ||
      selectedCategoriesOptions;

    filterProviders?.length > 0 &&
      filterProviders.map((item) => {
        switch (item.type) {
          case "hospital":
            hospitalsList.push(item.name);
            break;
          case "drug":
            drugsList.push(item.name);
            break;
          case "doctor":
            doctorsList.push(item.name);
            break;
          default:
            break;
        }
      });

    setDoctors(doctorsList);
    dispatch(
      Actions.Creators.switchState({
        doctor: doctorsList,
      }),
    );
    setDrugs(drugsList);
    dispatch(
      Actions.Creators.switchState({
        drug: drugsList,
      }),
    );
    setHospitals(hospitalsList);
    dispatch(
      Actions.Creators.switchState({
        hospital: hospitalsList,
      }),
    );

    dispatch(
      Actions.Creators.switchState({
        appliedDoctor: doctorsList,
      }),
    );

    dispatch(
      Actions.Creators.switchState({
        appliedDrug: drugsList,
      }),
    );

    dispatch(
      Actions.Creators.switchState({
        appliedHospital: hospitalsList,
      }),
    );

    const reportOnlyExpected = currentPreference?.onlyExpected || false;

    setServicesOptions(reportOnlyExpected);

    dispatch(
      Actions.Creators.switchState({
        serviceOption: reportOnlyExpected,
      }),
    );

    dispatch(
      Actions.Creators.switchState({
        hasSeparateFilter: hasSeparateFilterReport,
      }),
    );

    setHasSeparateFilters(hasSeparateFilterReport);

    dispatch(
      Actions.Creators.switchState({
        separateFilterValue: separateFilterValueReport,
      }),
    );

    setSeparateFilterValues(separateFilterValueReport);

    if (!applyLoading) {
      dispatch(
        Actions.Creators.switchState({
          broaderNetworkIncludes: broaderNetworkIncludesOnLoad,
        }),
      );
      setBroaderNetworks(broaderNetworkIncludesOnLoad);
    }

    if (preferencePlans && preferencePlans.length) {
      dispatch(
        Actions.Creators.switchState({
          selectedPlan: preferencePlans,
        }),
      );
      setSelectedPlans(preferencePlans);
    }

    if (preferenceCategories && preferenceCategories.length) {
      dispatch(
        Actions.Creators.switchState({
          selectedCategory: preferenceCategories,
        }),
      );
      setSelectedCategories(preferenceCategories);
    }

    if (currentPreference?.hsaHraImpact) {
      dispatch(
        Actions.Creators.switchState({
          hsaHraImpact: currentPreference?.hsaHraImpact,
        }),
      );

      setHsaHraImpacts(currentPreference?.hsaHraImpact);
    }

    if (currentPreference?.hsaContribution) {
      dispatch(
        Actions.Creators.switchState({
          hsaContribution: currentPreference?.hsaContribution,
        }),
      );

      setHsaContributions(currentPreference?.hsaContribution);
    }

    if (currentPreference?.hsaContributionOtherValue) {
      dispatch(
        Actions.Creators.switchState({
          hsaContributionOtherValue: currentPreference?.hsaContributionOtherValue,
        }),
      );

      setHsaContributionOtherValues(currentPreference?.hsaContributionOtherValue);
    }

    if (currentPreference?.taxBracket) {
      dispatch(
        Actions.Creators.switchState({
          taxBracket: currentPreference?.taxBracket,
        }),
      );

      setTaxBrackets(currentPreference?.taxBracket);
    }

    if (currentPreference?.hsaBalanceInvest != null) {
      dispatch(
        Actions.Creators.switchState({
          hsaBalanceInvest: currentPreference?.hsaBalanceInvest,
        }),
      );

      setHsaBalanceInvests(currentPreference?.hsaBalanceInvest);
    }

    if (currentPreference?.hsaBalanceReturn) {
      dispatch(
        Actions.Creators.switchState({
          hsaBalanceReturn: currentPreference?.hsaBalanceReturn,
        }),
      );

      setHsaBalanceReturns(currentPreference?.hsaBalanceReturn);
    }

    if (currentPreference?.hsaBalanceTimePeriod) {
      dispatch(
        Actions.Creators.switchState({
          hsaBalanceTimePeriod: currentPreference?.hsaBalanceTimePeriod,
        }),
      );

      setHsaBalanceTimePeriods(currentPreference?.hsaBalanceTimePeriod);
    }
  };

  useEffect(() => {
    setBroaderNetworks(broaderNetworkIncludesOnLoad);
  }, [broaderNetworkIncludesOnLoad]);

  const onClearAll = (doctors, hospitals, drugs, broaderNetworks, serviceOptions) => {
    setDoctors(doctors);
    dispatch(
      Actions.Creators.switchState({
        doctor: doctors,
      }),
    );
    setDrugs(drugs);
    dispatch(
      Actions.Creators.switchState({
        drug: drugs,
      }),
    );
    setServicesOptions(serviceOptions);
    dispatch(
      Actions.Creators.switchState({
        serviceOption: serviceOptions,
      }),
    );

    setHospitals(hospitals);
    dispatch(
      Actions.Creators.switchState({
        hospital: hospitals,
      }),
    );
    setBroaderNetworks(broaderNetworks);
    dispatch(
      Actions.Creators.switchState({
        broaderNetworkIncludes: broaderNetworks,
      }),
    );
  };

  return (
    <FilterContext.Provider
      value={{
        doctorOptions,
        drugOptions,
        hospitalOptions,
        broaderNetworksOptions,
        broaderNetworkIncludesOnLoad,
        filteredProvidersNotInNetwork,
        setFilteredProvidersNotInNetwork,
        renderFilteredProvidersNotInNetwork,
        handleApply,
        isDisabled,
        handleOtherValueChange,
        handleHsaBalanceReturn,
        handleHsaPeriod,
        visible,
        setVisible,
        noHsaOptionsModal,
        setNoHsaOptionsModal,
        filterUpdate,
        setFilterUpdate,
        applyLoading,
        setApplyLoading,
        canUpdate,
        setCanUpdate,
        isMenuOpen,
        closeMenu,
        hsaHraImpact,
        hsaContribution,
        hsaContributionOtherValue,
        hsaBalanceInvest,
        hsaBalanceReturn,
        hsaBalanceTimePeriod,
        taxBracket,
        selectedCategoriesOptions,
        selectedCategory,
        selectedPlansOptions,
        selectedPlan,
        currentPreference: report?.preferences?.["1"],
      }}
    >
      {children}
    </FilterContext.Provider>
  );
};

export const useFilterContext = () => {
  const context = React.useContext(FilterContext);

  if (context === undefined) {
    throw new Error("useFilterContext must be used within a FilterContextProvider");
  }

  return context;
};
