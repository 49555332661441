import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { PageTemplate, Header } from "components";
import { AddServicesStepper as Stepper, AddServicesSteps as Steps, AddServicesFooter as Footer } from "components";
import { Formik } from "formik";
import { Form } from "formik-antd";
import initialValues from "./utils/initialValues";
import { withFormikDevtools } from "formik-devtools-extension";
import { message } from "antd";
import { getToken } from "services/utils";
import { updatePlansServicesForm, updateReportServices } from "services/api/admin.api";
import { formatServices } from "./utils/format";
import { createValidationSchema } from "./utils/validationSchema";
import FormikPersist from "./utils/formikPersist";
import { useHistory, useLocation } from "react-router-dom";
import { loadServices } from "./utils/loadServices";
import { deleteData } from "indexedDB";

const AddServicesPage = () => {
  const history = useHistory();
  const { state } = useLocation();
  const [formikInitialValues, setFormikInitialValues] = useState(initialValues);
  const [finishedLoading, setFinishedLoading] = useState(false);
  const [validationSchema, setValidationSchema] = useState(null);

  const current = useSelector((state) => state.plan.current);
  const username = useSelector((state) => state.profile.username);
  const report = state?.report;
  const reportId = state?.reportId;
  const returnToDetailsPage = state?.returnToDetailsPage;
  const activeTab = returnToDetailsPage && state?.activeTab;
  const isMasterReport = state?.isMasterReport;

  if (!returnToDetailsPage) {
    history.push("/recommendations");
  }

  let token = {};

  const getValuesFromPlans = async (report) => {
    token = await getToken();
    const loadedServices = await loadServices(initialValues, report, token);
    setFormikInitialValues(loadedServices);
    localStorage.setItem(
      "servicesForm",
      JSON.stringify({
        reportId: report._id,
        household: report.type,
        children: report.event.general.children,
        isDue: report.isDue,
        ...loadedServices,
      }),
    );
    setFinishedLoading(true);
  };

  const servicesForm = JSON.parse(window.localStorage.getItem("servicesForm"));

  useEffect(() => {
    if (!(reportId && !localStorage.getItem("servicesForm"))) {
      setFinishedLoading(true);
    }
    reportId && !localStorage.getItem("servicesForm") && getValuesFromPlans(report);
    updateValidationSchema();
  }, []);

  const updateValidationSchema = () => {
    const servicesForm = JSON.parse(window.localStorage.getItem("servicesForm"));
    const fullValidationSchema = createValidationSchema(servicesForm);
    const editableValidationSchema = [...fullValidationSchema];
    if (isMasterReport) {
      editableValidationSchema.splice(0, 1);
      editableValidationSchema.splice(1, 2);
    } else if (!report.birthParent.canEditPlans && !report.partner.canEditPlans) {
      editableValidationSchema.splice(1, 2);
    } else if (!report.partner.canEditPlans || !report.partner.planIds?.length) {
      editableValidationSchema.splice(2, 1);
    } else if (!report.birthParent.canEditPlans) {
      editableValidationSchema.splice(1, 1);
    }

    setValidationSchema(editableValidationSchema);
  };

  const successAndRedirect = () => {
    message.success({
      content: <>{"Recommendation Updated"}</>,
      icon: (
        <span
          role="img"
          aria-label="check-circle"
          className="anticon anticon-check-circle"
          style={{
            color: "#00a19b",
          }}
        >
          <svg
            viewBox="64 64 896 896"
            focusable="false"
            data-icon="check-circle"
            width="1em"
            height="1em"
            fill="currentColor"
            aria-hidden="true"
          >
            <path d="M512 64C264.6 64 64 264.6 64 512s200.6 448 448 448 448-200.6 448-448S759.4 64 512 64zm193.5 301.7l-210.6 292a31.8 31.8 0 01-51.7 0L318.5 484.9c-3.8-5.3 0-12.7 6.5-12.7h46.9c10.2 0 19.9 4.9 25.9 13.3l71.2 98.8 157.2-218c6-8.3 15.6-13.3 25.9-13.3H699c6.5 0 10.3 7.4 6.5 12.7z"></path>
          </svg>
        </span>
      ),
    });
    localStorage.removeItem("servicesForm");
    deleteData(reportId);
    if (returnToDetailsPage) {
      history.push(`/optimizeV2/${reportId}`, {
        activeTab,
      });
    } else {
      history.push("/recommendations", {
        reportId,
        username,
      });
    }
  };

  return (
    <PageTemplate isContentHover padding="0 12.5%" header={<Header V2 />}>
      <Formik
        initialValues={formikInitialValues}
        validateOnMount={false}
        validationSchema={validationSchema && validationSchema[current]}
        onSubmit={async (values, { setSubmitting }) => {
          try {
            const { formattedPlans: plans, formattedServices: services } = formatServices(values);
            const formValues = JSON.stringify(values);
            const token = await getToken();
            const plansResponse = await updatePlansServicesForm(plans, reportId, token);
            setSubmitting(false);
            if (plansResponse.status === 200) {
              if (isMasterReport) {
                successAndRedirect();
              } else {
                const reportResponse = await updateReportServices(reportId, services, formValues, token);
                if (reportResponse.status == 200) {
                  successAndRedirect();
                } else message.error("Error updating report!");
              }
            } else message.error("Error updating plans!");
          } catch (e) {
            console.error(e);
          }
        }}
      >
        {(formikProps) => {
          withFormikDevtools(formikProps);
          return (
            finishedLoading && (
              <Form>
                <Stepper report={report} isMasterReport={isMasterReport} />
                <Steps
                  report={report}
                  isMasterReport={isMasterReport}
                  updateValidationSchema={updateValidationSchema}
                />
                <Footer isSubmitting={formikProps.isSubmitting} report={report} isMasterReport={isMasterReport} />
                <FormikPersist
                  name="servicesForm"
                  canCallOnSave={finishedLoading || Boolean(servicesForm)}
                  isMasterReport={isMasterReport}
                />
              </Form>
            )
          );
        }}
      </Formik>
    </PageTemplate>
  );
};

export default AddServicesPage;
