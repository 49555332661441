import React, { useMemo, useState } from "react";
import { Select } from "antd";
import styled, { css } from "styled-components";
import { Text } from "components";
import "antd/dist/antd.css";
import { isEmpty } from "utils/object";

const { Option } = Select;

let baseNewStyle = {
  fontSize: 14,
  height: 47,
  alignItems: "center",
  display: "flex",
  borderRadius: 8,
  border: "1px solid",
  textOverflow: "ellipsis",
};

const requiredNewStyle = {
  color: "#DC4C2D",
};

const newSelectStyle = {
  color: "#1c355e",
  borderColor: "#BBB",
};

const styles = {
  fontSize: 14,
  color: "rgb(0,161,155)",
};

const stylesRequired = {
  fontSize: 14,
  borderRadius: "4px",
  color: "#DC4C2D",
  border: "1px solid",
};

const bodStyles = (isBig) => {
  return {
    fontSize: 14,
  };
};

const Wrapper = styled.div`
  margin-bottom: ${({ isNotMargin }) => !isNotMargin && `5px`};
  margin-top: 30px;
  width: 100%;
  ${({ onboardingWrapper }) =>
    onboardingWrapper &&
    css`
      display: grid;
      grid-template-columns: 1fr;
      grid-gap: 20px;
      align-items: center;
      justify-items: center;
    `};
`;

const SelectWrapper = styled.div`
  ${({ onboardingWrapper }) =>
    onboardingWrapper &&
    css`
      background-color: rgba(81, 165, 162, 0.15);
      width: 310px;
      height: 60px;
      outline: none;
      border: none;
      padding: 5px 25px;
      border-radius: 15px;
      color: rgba(81, 165, 162);
      display: flex;
      justify-content: center;
      align-items: center;
    `}

  ${({ newStyle }) =>
    newStyle &&
    `
        font-family: circular;
  `}


  > .ant-select {
    width: ${({ width }) => (width ? width : "100%")};
  }
`;

const SelectCustom = ({
  text,
  selection,
  select,
  newStyle,
  selectObj,
  defaultOptionText,
  handleChange,
  name,
  isBool,
  isSearch,
  value,
  isBig,
  loading,
  title,
  noMargin,
  wide,
  disabled,
  width,
  onboardingWrapper,
}) => {
  const [focus, setFocus] = useState(false);
  const onFocus = () => {
    setFocus(true);
  };

  const onBlur = () => {
    setFocus(false);
  };

  const selectStyle = useMemo(() => {
    let selectStyle = value || value === false || focus || disabled ? styles : stylesRequired;
    return newStyle
      ? value || value === false || focus || disabled
        ? { ...baseNewStyle, ...newSelectStyle }
        : { ...baseNewStyle, ...requiredNewStyle }
      : selectStyle;
  }, [value, focus, disabled, newStyle]);

  const renderOptionWithSelect = (option, i) => {
    const valueSelect = select ? option[select] : option;
    const textValue = valueSelect + (title && i !== 0 ? title : "");
    return (
      <Option key={i} value={selectObj ? option[selectObj] : valueSelect}>
        {textValue}
      </Option>
    );
  };

  const renderOptionBool = (option, i) => (
    <Option key={i} value={i === 0 ? "false" : "true"}>
      {option}
    </Option>
  );

  const onChange = (value) => {
    const target = { name, value };
    handleChange({ target });
  };

  const strToBool = (str) => {
    if (str === "true") return true;
    else if (str === "false") return false;
    else return str;
  };

  const renderValue = (value) => {
    if (selectObj && select && value) {
      const valueObj = selection.find((selected) => strToBool(selected[selectObj]) === value);

      return valueObj && !isEmpty(valueObj) && valueObj[select];
    } else if (selectObj === "tier" && value) {
      return value;
    } else if (value || value === false) {
      return value + "";
    } else if (disabled) {
      return;
    } else if (defaultOptionText) {
      return defaultOptionText;
    } else {
      return "Select an Option";
    }
  };

  return (
    <Wrapper newStyle={newStyle} isNotMargin={noMargin} wide={wide} width={width}>
      <SelectWrapper newStyle={newStyle} onboardingWrapper={onboardingWrapper}>
        <Text select={!newStyle} newSelect={newStyle}>
          {text}
        </Text>
        <Select
          bordered={!newStyle}
          loading={loading}
          showSearch={isSearch}
          disabled={disabled || selection.length === 0}
          size="default"
          value={renderValue(value)}
          style={selectStyle}
          optionFilterProp="children"
          onChange={onChange}
          dropdownMatchSelectWidth={false}
          dropdownStyle={bodStyles(isBig)}
          filterOption={(input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0}
          onFocus={onFocus}
          onBlur={onBlur}
        >
          {!isBool ? selection.map(renderOptionWithSelect) : selection.map(renderOptionBool)}
        </Select>
      </SelectWrapper>
    </Wrapper>
  );
};

export default React.memo(SelectCustom);
