import { Menu, Modal, Empty, Button as ButtonModal, List } from "antd";
import "./styles.css";

import { Checkbox, Radio } from "antd";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { createNewPreference, getPlansArr, optimizeV2 } from "services/api";
import { getToken } from "services/utils";
import { isEmpty } from "utils/object";
import { CheckOutlined } from "@ant-design/icons";

const CustomRadioCheckGroup = styled.ul`
  li {
    display: flex;
    width: 100%;
    padding: 6px;
    border: 2px solid #d0d5dd;
    color: #21355b;
    border-radius: 8px;
    margin: 8px 0;
    font-weight: 500;

    label {
      display: flex;
      width: 100%;
      cursor: pointer;

      .radio-plans-label,
      .checkbox-plans-label {
        line-height: initial;
        margin: 6px;
      }
    }

    .check-input-radio-wrapper,
    .check-input-checkbox-wrapper {
      position: relative;
      background-color: transparent;
      border-radius: 100%;
      border: 2px solid #21355b;
      height: 20px;
      width: 20px;
      min-width: 20px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 6px;

      &.checked {
        background-color: #21355b;

        .check-icon-radio,
        .check-icon-checkbox {
          display: inline;
          filter: invert(1);
        }
      }

      input {
        display: none;
      }

      .check-icon-radio,
      .check-icon-checkbox {
        display: none;
        top: 0;
        width: max-content;
        height: max-content;
      }
    }

    &.group-no-border {
      border: none;
      padding: 0;

      .check-input-radio-wrapper {
        border-radius: 4px;
      }
    }
  }
`;

const Wrapper = styled.div`
  padding: 30px 0px;
  width: 100%;
`;

const ItensWrapper = styled.div`
  padding: 15px 0;
`;

const ItensWrapperHeading = styled.h3`
  font-weight: bold;
  color: #21355b;
  margin-bottom: 10px;
  line-height: initial;
`;

const InnerWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  h1 {
    font-size: 18px;
    font-weight: bolder;
    margin-bottom: 0;
  }
  span {
    font-size: 16px;
    font-weight: lighter;
    text-decoration: underline;
    cursor: pointer;
  }
`;

const Button = styled.button`
  border: none;
  background: #16a19c;
  color: white;
  border-radius: 5px;
  font-size: 16px;
  margin-left: 15px;
  padding: 0px 15px;
  cursor: pointer;
  ${({ disabled }) => disabled && `background-color: #b3bac5;cursor: not-allowed; pointer-events: all !important`}
`;

const StylesModal = styled(Modal)`
  font-family: circular;

  .ant-modal-close-x {
    font-size: 24px;
    margin-right: 0;
    margin-top: 0;
  }
  .ant-modal-body {
    padding-bottom: 24px;
  }
  .ant-modal-header {
    border-bottom: 0 none;
  }
  .ant-modal-footer {
    border-top: 0 none;
  }
`;

const Filter = ({
  providersList,
  report,
  updateState,
  optimizations,
  otherOptimizations,
  broaderNetworksFromAllMyOptions,
  closeMenu,
  setCanUpdate,
}) => {
  const [doctors, setDoctors] = useState([]);
  const [drugs, setDrugs] = useState([]);
  const [hospitals, setHospitals] = useState([]);
  const [broaderNetworks, setBroaderNetworks] = useState([]);

  const [doctorOptions, setDoctorOptions] = useState([]);
  const [drugOptions, setDrugOptions] = useState([]);
  const [hospitalOptions, setHospitalOptions] = useState([]);
  const [broaderNetworksOptions, setBroaderNetworksOptions] = useState([]);
  const [filteredProvidersNotInNetwork, setFilteredProvidersNotInNetwork] = useState({});

  const [visible, setVisible] = useState(false);

  const doctor = useSelector((state) => state.report.doctor);
  const hospital = useSelector((state) => state.report.hospital);
  const drug = useSelector((state) => state.report.drug);

  const reportJoin = report.preferences["1"].join;

  const broaderNetworkIncludes = useSelector((state) => state.report.broaderNetworkIncludes);

  const plansToJoin = useSelector((state) => state.report.plansToJoin);

  const broaderNetworkIncludesOnLoad = useSelector((state) => state.report.broaderNetworkIncludesOnLoad);

  const broaderNetworkPlans = useSelector((state) => state.report.broaderNetworkPlans);

  const customPreferenceOne = useSelector((state) => state.report.customPreferenceOne);
  const customPreferenceTwo = useSelector((state) => state.report.customPreferenceTwo);

  const dispatch = useDispatch();

  const handleFilteredProvidersNotInNetwork = (filteredProviders) => {
    let isEmpty = true;
    Object.values(filteredProviders).map((item) => {
      if (item.length) isEmpty = false;
    });
    if (!isEmpty) {
      setFilteredProvidersNotInNetwork(filteredProviders);
      setVisible(true);
    }
  };

  const renderFilteredProvidersNotInNetwork = (filteredProviders) => {
    const data = filteredProviders ? Object.entries(filteredProviders) : [];
    return (
      <List
        size="small"
        // header={<div>Header</div>}
        // footer={<div>Footer</div>}
        dataSource={data}
        renderItem={(item) =>
          !!item[1].length && (
            <List.Item>
              {item[0]} is not in-network with {item[1].join(", ")}.
            </List.Item>
          )
        }
      />
    );
  };

  const updateCustomPreference = async () => {
    const { custom, custom2 } = report.preferences;
    if (custom?.apply && isEmpty(customPreferenceOne)) {
      const opt = await optimizeV2({
        reportId: report._id,
        preferenceKey: "custom",
      });
      opt.status === 200 &&
        dispatch(
          Actions.Creators.switchState({
            report: opt.data.report,
            customPreferenceOne: opt.data.filteredResults[0]?.selected,
          }),
        );
    }
    if (custom2?.apply && isEmpty(customPreferenceTwo)) {
      const opt = await optimizeV2({
        reportId: report._id,
        preferenceKey: "custom2",
      });
      opt.status === 200 &&
        dispatch(
          Actions.Creators.switchState({
            report: opt.data.report,
            customPreferenceTwo: opt.data.filteredResults[0]?.selected,
          }),
        );
    }
  };

  const handleApply = async () => {
    const filterDoctors = doctor?.map((item) => {
      return { name: item, type: "doctor" };
    });
    const filterHospitals = hospital?.map((item) => {
      return { name: item, type: "hospital" };
    });
    const drugDoctors = drug?.map((item) => {
      return { name: item, type: "drug" };
    });

    let filteredBroaderNetworkIncludes = broaderNetworkIncludes;
    const filteredProviders = {};

    broaderNetworksFromAllMyOptions.map((network, _i) => {
      const { providers, broaderNetwork } = network;
      const { doctor: doctors, drug: drugs, hospital: hospitals } = providers;
      let hospitalFiltered = !hospital.every((i) => hospitals.includes(i));
      let drugFiltered = !drug.every((i) => drugs.includes(i));
      let doctorFiltered = !doctor.every((i) => doctors.includes(i));
      const isFilteredRow = hospitalFiltered || drugFiltered || doctorFiltered;

      if (isFilteredRow) {
        filteredBroaderNetworkIncludes = filteredBroaderNetworkIncludes.filter((e) => e !== broaderNetwork);

        const hospitalNames = hospital.filter((x) => !hospitals.includes(x));
        const drugNames = drug.filter((x) => !drugs.includes(x));
        const doctorNames = doctor.filter((x) => !doctors.includes(x));
        const names = [...hospitalNames, ...drugNames, ...doctorNames];
        names.map((item) => {
          filteredProviders[item] = filteredProviders[item] || [];
        });

        let difference = broaderNetworkIncludes?.filter((x) => !broaderNetworkIncludesOnLoad.includes(x));

        difference.includes(broaderNetwork) &&
          names.length > 0 &&
          names.map((item) => {
            filteredProviders[item] = [...filteredProviders[item], broaderNetwork];
          });
      }

      isFilteredRow &&
        dispatch(
          Actions.Creators.switchState({
            broaderNetworkIncludes: filteredBroaderNetworkIncludes,
          }),
        );
    });

    handleFilteredProvidersNotInNetwork(filteredProviders);

    const include = {
      birthParentPlans: [],
      partnerPlans: [],
    };
    filteredBroaderNetworkIncludes.map((item) => {
      include.birthParentPlans = [...include.birthParentPlans, ...broaderNetworkPlans[item]["birthParentPlans"]];
      include.partnerPlans = [...include.partnerPlans, ...broaderNetworkPlans[item]["partnerPlans"]];
    });
    const newPreference = {
      ...report.preferences["1"],
      filterProviders: [...filterHospitals, ...drugDoctors, ...filterDoctors],
      join: plansToJoin,
      include,
    };
    const token = await getToken();
    const res = await createNewPreference(report._id, "1", newPreference, token);

    if (res.status === 200) {
      try {
        const opt = await optimizeV2({
          reportId: report._id,
          preferenceKey: 1,
        });
        updateState(opt);
        const optimization = opt.data.filteredResults[0].selected;

        dispatch(Actions.Creators.loadRecommendedByOptmization(optimization));

        const { birthParent, partner } = optimization.optimizationDetails;
        const {
          broaderNetworks,
          providersGrid,
          event,
          isDue,
          partner: partnerFromReport,
          birthParent: birthParentFromReport,
        } = report;
        const { birthParentPlans, partnerPlans } = include;

        const plansIds = [birthParent.externalId, partner.externalId].filter((el) => Boolean(el));
        const data = await getPlansArr(plansIds, token);

        dispatch(
          Actions.Creators.loadRecommendedByReport({
            data,
            broaderNetworks,
            providersGrid,
            optimization: optimization,
            event,
            isDue,
            broaderNetworksFromAllMyOptions,
            plansFromPartner: partnerFromReport.planIds,
            plansFromBirthParent: birthParentFromReport.planIds,
            preferenceBirthParentPlans: birthParentPlans,
            preferencePartnerPlans: partnerPlans,
            join: plansToJoin,
          }),
        );
        dispatch(
          Actions.Creators.switchState({
            appliedDoctor: doctor,
          }),
        );

        dispatch(
          Actions.Creators.switchState({
            appliedDrug: isDrugDefault,
          }),
        );

        dispatch(
          Actions.Creators.switchState({
            appliedHospital: hospital,
          }),
        );
        onClearAll(doctor, hospital, drug, filteredBroaderNetworkIncludes);
      } catch (error) {
        console.log("error", error);
        setVisible(true);
        onClearAll(doctors, hospitals, drugs, broaderNetworks);
        dispatch(
          Actions.Creators.switchState({
            plansToJoin: reportJoin,
          }),
        );
        await createNewPreference(report._id, "1", report.preferences["1"], token);
      }
    }
    updateCustomPreference();
  };

  const isDoctorDefault = doctor.sort().toString() == doctors.sort().toString();
  const isHospitalDefault = hospital.sort().toString() == hospitals.sort().toString();
  const isDrugDefault = drug.sort().toString() == drugs.sort().toString();
  const isBroaderNetworkDefault = broaderNetworks.sort().toString() == broaderNetworkIncludes.sort().toString();

  const isDisabled =
    isDoctorDefault && isHospitalDefault && isDrugDefault && isBroaderNetworkDefault && reportJoin === plansToJoin;
  // || applyDisabled;

  useEffect(() => {
    setCanUpdate(!isDisabled);
  }, [isDisabled]);

  useEffect(() => {
    let doctorsList = [];
    let drugsList = [];
    let hospitalsList = [];
    providersList.map((item) => {
      switch (item.type) {
        case "Hospital":
          hospitalsList.push(item.name);
          break;
        case "Drug":
          drugsList.push(item.name);
          break;
        case "Doctor":
          doctorsList.push(item.name);
          break;
        default:
          break;
      }
    });
    const broaderNetworksList = report.broaderNetworks.map((item) => {
      return item.name;
    });
    setDoctorOptions(doctorsList);
    setDrugOptions(drugsList);
    setHospitalOptions(hospitalsList);
    setBroaderNetworksOptions(broaderNetworksList);
  }, [providersList]);

  useEffect(() => {
    let doctorsList = [];
    let drugsList = [];
    let hospitalsList = [];
    const filterProviders = report.preferences[1]?.filterProviders;

    filterProviders?.length > 0 &&
      filterProviders.map((item) => {
        switch (item.type) {
          case "hospital":
            hospitalsList.push(item.name);
            break;
          case "drug":
            drugsList.push(item.name);
            break;
          case "doctor":
            doctorsList.push(item.name);
            break;
          default:
            break;
        }
      });
    setDoctors(doctorsList);
    dispatch(
      Actions.Creators.switchState({
        doctor: doctorsList,
      }),
    );
    setDrugs(drugsList);
    dispatch(
      Actions.Creators.switchState({
        drug: drugsList,
      }),
    );
    setHospitals(hospitalsList);
    dispatch(
      Actions.Creators.switchState({
        hospital: hospitalsList,
      }),
    );

    dispatch(
      Actions.Creators.switchState({
        appliedDoctor: doctorsList,
      }),
    );

    dispatch(
      Actions.Creators.switchState({
        appliedDrug: drugsList,
      }),
    );

    dispatch(
      Actions.Creators.switchState({
        appliedHospital: hospitalsList,
      }),
    );
    setBroaderNetworks(broaderNetworkIncludes);
  }, [report]);

  const onClearAll = (doctors, hospitals, drugs, broaderNetworks) => {
    setDoctors(doctors);
    dispatch(
      Actions.Creators.switchState({
        doctor: doctors,
      }),
    );
    setDrugs(drugs);
    dispatch(
      Actions.Creators.switchState({
        drug: drugs,
      }),
    );
    setHospitals(hospitals);
    dispatch(
      Actions.Creators.switchState({
        hospital: hospitals,
      }),
    );
    setBroaderNetworks(broaderNetworks);
    dispatch(
      Actions.Creators.switchState({
        broaderNetworkIncludes: broaderNetworks,
      }),
    );
  };

  const noProviders = !doctorOptions.length && !drugOptions.length && !hospitalOptions.length;

  const plansToJoinArray = [
    { value: "all", label: "All Combinations" },
    { value: "same", label: "Same Plan" },
    { value: "separate", label: "Separate Plans" },
    {
      value: "birthParent",
      label: report.birthParent.company,
    },
    { value: "partner", label: report.partner.company },
  ];

  return (
    <Wrapper className="filter">
      <Menu onClick={() => {}} defaultOpenKeys={["sub1", "sub2", "sub3", "sub4", "sub5"]} mode="inline" multiple>
        {report?.birthParent?.company && report?.partner?.company && !report.partner?.justForComparison && (
          <ItensWrapper>
            <ItensWrapperHeading>Plans to Join:</ItensWrapperHeading>
            <CustomRadioCheckGroup>
              {plansToJoinArray?.map?.((thisPlan) => (
                <li>
                  <label>
                    <div className={`check-input-radio-wrapper ${thisPlan.value === plansToJoin ? "checked" : ""}`}>
                      <input
                        type="radio"
                        name="radio-plans-to-join"
                        value={thisPlan.value}
                        checked={thisPlan.value === plansToJoin}
                        onChange={(e) => {
                          dispatch(
                            Actions.Creators.switchState({
                              plansToJoin: e.target.value,
                            }),
                          );
                        }}
                      />
                      <div className="check-icon-radio">
                        <CheckOutlined />
                      </div>
                    </div>
                    <span className="radio-plans-label">{thisPlan.label}</span>
                  </label>
                </li>
              ))}
            </CustomRadioCheckGroup>
          </ItensWrapper>
        )}

        {doctorOptions.length > 0 && (
          <ItensWrapper>
            <ItensWrapperHeading>In-Network Doctors</ItensWrapperHeading>
            <CustomRadioCheckGroup>
              {doctorOptions?.map?.((docOpt) => (
                <li>
                  <label>
                    <div className={`check-input-checkbox-wrapper ${doctor.includes(docOpt) ? "checked" : ""}`}>
                      <input
                        type="checkbox"
                        checked={doctor.includes(docOpt)}
                        onClick={() => {
                          const index = doctor?.indexOf?.(docOpt);
                          let newDoctor = JSON.parse(JSON.stringify(doctor));

                          if (index < 0) {
                            newDoctor.push(docOpt);
                          } else {
                            newDoctor.splice(index, 1);
                          }

                          dispatch(
                            Actions.Creators.switchState({
                              doctor: newDoctor,
                            }),
                          );
                        }}
                      />
                      <div className="check-icon-checkbox">
                        <CheckOutlined />
                      </div>
                    </div>
                    <span className="checkbox-plans-label">{docOpt}</span>
                  </label>
                </li>
              ))}
            </CustomRadioCheckGroup>
          </ItensWrapper>
        )}
        {drugOptions.length > 0 && (
          <ItensWrapper>
            <ItensWrapperHeading>In-Network Drugs</ItensWrapperHeading>
            <CustomRadioCheckGroup>
              {drugOptions?.map?.((drugOpt) => (
                <li>
                  <label>
                    <div className={`check-input-checkbox-wrapper ${drug.includes(drugOpt) ? "checked" : ""}`}>
                      <input
                        type="checkbox"
                        checked={drug.includes(drugOpt)}
                        onClick={() => {
                          const index = drug?.indexOf?.(drugOpt);
                          let newDrug = JSON.parse(JSON.stringify(drug));

                          if (index < 0) {
                            newDrug.push(drugOpt);
                          } else {
                            newDrug.splice(index, 1);
                          }

                          dispatch(
                            Actions.Creators.switchState({
                              drug: newDrug,
                            }),
                          );
                        }}
                      />
                      <div className="check-icon-checkbox">
                        <CheckOutlined />
                      </div>
                    </div>
                    <span className="checkbox-plans-label">{drugOpt}</span>
                  </label>
                </li>
              ))}
            </CustomRadioCheckGroup>
          </ItensWrapper>
        )}
        {hospitalOptions.length > 0 && (
          <ItensWrapper>
            <ItensWrapperHeading>In-Network Hospital</ItensWrapperHeading>
            <CustomRadioCheckGroup>
              {hospitalOptions?.map?.((hospitalOpt) => (
                <li>
                  <label>
                    <div className={`check-input-checkbox-wrapper ${hospital.includes(hospitalOpt) ? "checked" : ""}`}>
                      <input
                        type="checkbox"
                        checked={hospital.includes(hospitalOpt)}
                        onClick={() => {
                          const index = hospital?.indexOf?.(hospitalOpt);
                          let newHospital = JSON.parse(JSON.stringify(hospital));

                          if (index < 0) {
                            newHospital.push(hospitalOpt);
                          } else {
                            newHospital.splice(index, 1);
                          }

                          dispatch(
                            Actions.Creators.switchState({
                              hospital: newHospital,
                            }),
                          );
                        }}
                      />
                      <div className="check-icon-checkbox">
                        <CheckOutlined />
                      </div>
                    </div>
                    <span className="checkbox-plans-label">{hospitalOpt}</span>
                  </label>
                </li>
              ))}
            </CustomRadioCheckGroup>
          </ItensWrapper>
        )}
        {broaderNetworksOptions.length > 0 && (
          <ItensWrapper>
            <ItensWrapperHeading>Insurance Networks</ItensWrapperHeading>
            <CustomRadioCheckGroup>
              {broaderNetworksOptions?.map?.((broadNetOpt) => (
                <li>
                  <label>
                    <div
                      className={`check-input-checkbox-wrapper ${
                        broaderNetworkIncludes.includes(broadNetOpt) ? "checked" : ""
                      }`}
                    >
                      <input
                        type="checkbox"
                        checked={broaderNetworkIncludes.includes(broadNetOpt)}
                        onClick={() => {
                          const index = broaderNetworkIncludes?.indexOf?.(broadNetOpt);
                          let newBroaderNetworkIncludes = JSON.parse(JSON.stringify(broaderNetworkIncludes));

                          if (index < 0) {
                            newBroaderNetworkIncludes.push(broadNetOpt);
                          } else {
                            newBroaderNetworkIncludes.splice(index, 1);
                          }

                          dispatch(
                            Actions.Creators.switchState({
                              broaderNetworkIncludes: newBroaderNetworkIncludes,
                            }),
                          );
                        }}
                      />
                      <div className="check-icon-checkbox">
                        <CheckOutlined />
                      </div>
                    </div>
                    <span className="checkbox-plans-label">{broadNetOpt}</span>
                  </label>
                </li>
              ))}
            </CustomRadioCheckGroup>
          </ItensWrapper>
        )}

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
          }}
        >
          <button
            id="filter-sidebar-update-btn"
            style={{
              border: `2px solid ${isDisabled ? "#D0D5DD" : "#21355B"}`,
              background: isDisabled ? "#D0D5DD" : "#21355B",
              color: "#FFF",
              borderRadius: "5px",
              fontSize: "24px",
              padding: "0px 36px",
              cursor: "pointer",
              lineHeight: "normal",
              marginBottom: "10px",
            }}
            disabled={isDisabled}
            onClick={handleApply}
          >
            Update
          </button>
          <button
            style={{
              border: "2px solid #D0D5DD",
              background: "transparent",
              color: "#21355B",
              borderRadius: "5px",
              fontSize: "16px",
              padding: "0px 24px",
              cursor: "pointer",
              lineHeight: "normal",
            }}
            onClick={closeMenu}
          >
            Cancel
          </button>
        </div>
      </Menu>

      <StylesModal
        title={
          !isEmpty(filteredProvidersNotInNetwork)
            ? "Try clearing some of your filters to get a recommendation"
            : "No recommendation matching your filters"
        }
        visible={visible}
        footer={[
          <ButtonModal
            key="submit"
            type="primary"
            onClick={() => {
              setVisible(false);
              setFilteredProvidersNotInNetwork({});
            }}
          >
            Ok
          </ButtonModal>,
        ]}
        onCancel={() => {
          setVisible(false);
          setFilteredProvidersNotInNetwork({});
        }}
      >
        {!isEmpty(filteredProvidersNotInNetwork) && visible ? (
          renderFilteredProvidersNotInNetwork(filteredProvidersNotInNetwork)
        ) : (
          <Empty description={<span>Try clearing your filters to see a recommendation.</span>} />
        )}
      </StylesModal>
    </Wrapper>
  );
};

export default Filter;
