import React, { useEffect, useState } from "react";
import styled from "styled-components";

const Overlay = styled.div`
  background-color: #000;
  height: 100%;
  width: 100vw;
  position: fixed;
  overflow: hidden;
  top: 0;
  left: 0;
  opacity: 0.8;
  z-index: 100;
`;

export const ConfirmWrapper = styled.div`
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 102;
`;

export const ConfirmModal = styled.div`
  background-color: #fff;
  max-width: 360px;
  border-radius: 8px;
  padding: 20px;
  color: #1c355e;

  p {
    text-align: center;
    margin: 0;
  }

  button {
    font-size: 14px;
    padding: 8px;
    border: 1px solid #1c355e;
    border-radius: 8px;
    background-color: #fff;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  div {
    padding-top: 20px;
    display: flex;
    justify-content: center;
    gap: 4px;
  }
`;

export default function SideBarOverlay({ sidebarOpen, setSideBarOpen, applyLoading, canUpdate }) {
  const [confirmClose, setConfirmClose] = useState(false);
  const [waitForLoad, setWaitForLoad] = useState(false);

  function closeSiderBar(andSave) {
    if (!andSave) {
      setWaitForLoad(false);
      setConfirmClose(false);
      setSideBarOpen(false);
    } else {
      const updateBtnFromFilter = document.querySelector("#filter-sidebar-update-btn");
      if (!updateBtnFromFilter) return;
      updateBtnFromFilter.click();
      setWaitForLoad(true);
    }
  }

  useEffect(() => {
    if (!applyLoading && waitForLoad) {
      setWaitForLoad(false);
      setConfirmClose(false);
      setSideBarOpen(false);
    }
  }, [applyLoading, waitForLoad]);

  return (
    <>
      {sidebarOpen && (
        <>
          <Overlay
            onClick={() => {
              if (canUpdate) {
                setConfirmClose(true);
              } else {
                closeSiderBar(false);
              }
            }}
          ></Overlay>
          {confirmClose && (
            <ConfirmWrapper>
              <ConfirmModal>
                {applyLoading ? (
                  <p>Updating...</p>
                ) : (
                  <>
                    <p>Would you like to update your results with the changes you just made?</p>
                    <div>
                      <button type="button" onClick={() => closeSiderBar(true)}>
                        Yes, update with changes
                      </button>
                      <button type="button" onClick={() => closeSiderBar(false)}>
                        No, exit without saving
                      </button>
                    </div>
                  </>
                )}
              </ConfirmModal>
            </ConfirmWrapper>
          )}
        </>
      )}
    </>
  );
}
