import Button from "components/atoms/Button";
import Tag from "components/atoms/Tag";
import Text from "components/atoms/Text";
import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { createNewPreference, disablePreference, optimizeV2 } from "services/api";
import { getToken } from "services/utils";
import Actions from "store/report/actions";
import styled from "styled-components";
import { isEmpty } from "utils/object";
import ComparisonModeSwitch from "../ComparisonModeSwitch";

const CoverageWrapper = styled.div`
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid white;
`;

const ComparisonMode = ({ report, changeSideBar }) => {
  const dispatch = useDispatch();
  const comparisonMode = useSelector((state) => state.report.comparisonMode);
  const plansMode = useSelector((state) => state.report.plansMode);
  const children = useSelector((state) => state.report.children);

  const planSelectedFrom = useSelector((state) => state.report.planSelectedFrom);
  const customPreferenceOne = useSelector((state) => state.report.customPreferenceOne);
  const customPreferenceTwo = useSelector((state) => state.report.customPreferenceTwo);
  const comparedPlanYou = useSelector((state) => state.report.comparedPlanYou);
  const comparedPlanSpouse = useSelector((state) => state.report.comparedPlanSpouse);

  const objComparedPlanYou = comparedPlanYou && JSON.parse(comparedPlanYou);
  const objComparedPlanSpouse = comparedPlanSpouse && JSON.parse(comparedPlanSpouse);

  const labelComparedPlanYou = `${objComparedPlanYou.network} ${objComparedPlanYou.planName}`;
  const labelComparedPlanSpouse = `${objComparedPlanSpouse.network} ${objComparedPlanSpouse.planName}`;

  // const isJobOffer = report.spouse?.justForComparison === "jobOffer" || false;

  const renderButtonText = () => {
    const empty = !comparedPlanYou && !comparedPlanSpouse;
    const needSpouse = plansMode === "separatePlans" && !comparedPlanSpouse;
    const needYou = plansMode === "separatePlans" && !comparedPlanYou;

    return empty
      ? "Select a plan"
      : needSpouse
      ? "Select spouse plan"
      : needYou
      ? "Select your plan"
      : "Compare this coverage";
  };

  const onDeletePlan = (e, type) => {
    e.preventDefault();
    console.log(e);
    const reset = type === "birthParent" ? { comparedPlanYou: "" } : { comparedPlanSpouse: "" };
    dispatch(Actions.Creators.switchState(reset));
  };

  const onDeleteCoverage = async (e, preference) => {
    e.preventDefault();
    const reset = preference === 1 ? { customPreferenceOne: {} } : { customPreferenceTwo: {} };

    const token = await getToken();
    const custom = preference === 1 ? "custom" : "custom2";

    await disablePreference(report._id, custom, token);
    dispatch(Actions.Creators.switchState(reset));
  };

  const handleCompare = async () => {
    const includeBirthParentPlans = [
      plansMode === "samePlan" && planSelectedFrom === "partner" ? undefined : objComparedPlanYou?.externalId,
    ];
    const includePartnerPlans = [
      plansMode === "samePlan" && planSelectedFrom === "partner"
        ? objComparedPlanYou?.externalId
        : objComparedPlanSpouse?.externalId,
    ];

    const joinCoverage = plansMode === "separatePlans" ? "separate" : planSelectedFrom;

    const newCoverage = {
      title: `Custom Coverage #${isEmpty(customPreferenceOne) ? "1" : "2"}`,
      subTitle: "",
      apply: true,
      join: joinCoverage,
      cost: "lowest",
      childrenJoin: plansMode === "separatePlans" && children ? children : null,
      include: {
        birthParentPlans: includeBirthParentPlans,
        partnerPlans: includePartnerPlans,
      },
      exclude: {
        birthParentPlans: [],
        partnerPlans: [],
      },
      lifeEvent: {
        name: null,
        date: null,
        allowSwitch: false,
      },
      bills: {
        inNetworkParent: 25285,
        hospitalVisits: 1,
      },
    };
    const token = await getToken();
    const custom = !isEmpty(customPreferenceOne) ? "custom2" : "custom";
    const res = await createNewPreference(report._id, custom, newCoverage, token);

    const opt = res.status === 200 && (await optimizeV2({ reportId: report._id, preferenceKey: custom }));

    if (opt.status === 200) {
      let customPreference = opt.data.filteredResults[0].selected;
      const customState = !isEmpty(customPreferenceOne)
        ? {
            report: opt.data.report,
            customPreferenceTwo: customPreference,
          }
        : {
            report: opt.data.report,
            customPreferenceOne: customPreference,
          };
      dispatch(Actions.Creators.switchState(customState));
      dispatch(Actions.Creators.switchState({ comparedPlanYou: "" }));
      dispatch(Actions.Creators.switchState({ comparedPlanSpouse: "" }));
      changeSideBar("4");
      window.scrollTo(0, 0);
    }
  };

  return (
    <>
      {report.version === "v1" && <ComparisonModeSwitch />}

      {comparisonMode === "on" && (
        <>
          <Text italic center blue desktopMaxWidth="33vw" style={{ margin: "15px 0" }} fontSize="14">
            Select {plansMode === "samePlan" ? "1 coverage combination" : "2 coverage combinations"} to compare in more
            detail with the Recommended coverage. Then select the button below to confirm.
          </Text>

          <CoverageWrapper>
            {!isEmpty(customPreferenceOne) && (
              <Tag coverage closable onClose={(e) => onDeleteCoverage(e, 1)}>
                Custom Coverage #1
              </Tag>
            )}
            {!isEmpty(customPreferenceTwo) && (
              <Tag coverage closable onClose={(e) => onDeleteCoverage(e, 2)}>
                Custom Coverage #2
              </Tag>
            )}
          </CoverageWrapper>

          {comparedPlanYou && (
            <Tag
              icon={children === "birthParent" && plansMode === "separatePlans"}
              closable
              onClose={(e) => onDeletePlan(e, "birthParent")}
            >
              {labelComparedPlanYou}
            </Tag>
          )}
          {comparedPlanSpouse && (
            <Tag icon={children === "partner"} closable onClose={(e) => onDeletePlan(e, "partner")}>
              {labelComparedPlanSpouse}
            </Tag>
          )}
          <Button
            disabled={
              (plansMode === "samePlan" ? !comparedPlanYou : !comparedPlanYou || !comparedPlanSpouse) ||
              (!isEmpty(customPreferenceOne) && !isEmpty(customPreferenceTwo))
            }
            fullwidth
            onClick={handleCompare}
          >
            {renderButtonText()}
          </Button>
        </>
      )}
    </>
  );
};

export default ComparisonMode;
