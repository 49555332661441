import { createReducer } from "reduxsauce";
import Actions from "./actions";
import _, { assign, isUndefined } from "lodash";
import { saveData, loadData } from "indexedDB";

const { Types } = Actions;

const INITIAL_STATE = {
  recommendedText: [],
  recommendedPlan: [],
  recommendedId: [],
  recommendedNetwork: [],
  highlightText: [],
  noteText: "",
  recommendedIndex: [],
  howManyHospitals: "",
  howManyDoctors: "",
  howManyDrugs: "",
  recommendedFor: ["", ""],
  costPeriod: "yearly",
  comparisonMode: "off",
  plansMode: "separatePlans",
  isSepareteRecommended: false,
  hasNotSeparate: false,
  childrenRecommended: "birthParent",
  hasKid: false,
  children: "birthParent",
  join: "separatePlans",
  detailsJoin: "",
  planSelectedFrom: "",
  comparedPlanYou: "",
  comparedPlanSpouse: "",
  customPreferenceOne: {},
  customPreferenceTwo: {},
  drug: [],
  serviceOption: false,
  hsaHraImpact: "spending-only",
  hsaContribution: "nothing",
  hsaContributionOtherValue: 500,
  taxBracket: 37,
  hsaBalanceInvest: true,
  hsaBalanceReturn: 10,
  hsaBalanceTimePeriod: 1,
  hospital: [],
  doctor: [],
  appliedDrug: [],
  appliedHospital: [],
  appliedDoctor: [],
  broaderNetworkIncludes: [],
  plansToJoin: "",
  broaderNetworkIncludesOnLoad: [],
  broaderNetworkJoin: {},
  broaderNetworkPlans: {},
  applyDisabled: true,
  selectedPlan: [],
  selectedCategory: [],
  hasSeparateFilter: false,
  separateFilterValue: 2000,
};

const loadRecommendedByOptmization = (state = INITIAL_STATE, { payload }) => {
  let recommendedText = [];
  let recommendedPlan = [];
  let recommendedId = [];
  let recommendedNetwork = [];
  let plansMode = "separatePlans";
  let join = "separatePlans";
  let isSepareteRecommended = false;
  if (!payload) return;
  const details = payload.optimizationDetails;
  let childrenRecommended = details.childrenJoin;
  let children = details.childrenJoin;
  let detailsJoin = details.join;
  if (detailsJoin === "separate") {
    recommendedText = [
      `${details.birthParent.company} ${details.birthParent.network} ${
        payload.version === "v1" ? details.birthParent.plan : ""
      }`,
      `${details.partner.company} ${details.partner.network} ${payload.version === "v1" ? details.partner.plan : ""}`,
    ];
    recommendedPlan = [details.birthParent.plan, details.partner.plan];
    recommendedNetwork = [details.birthParent.broaderNetwork, details.partner.broaderNetwork];
    recommendedId = [details.birthParent.externalId, details.partner.externalId];
    isSepareteRecommended = true;
  } else {
    recommendedText = [`${details[detailsJoin].company} ${details[detailsJoin].network} ${details[detailsJoin].plan}`];
    recommendedPlan = [details[detailsJoin].plan];
    recommendedNetwork = [details[detailsJoin].broaderNetwork];
    recommendedId = [details[detailsJoin].externalId];
    plansMode = "samePlan";
    join = "samePlan";
  }
  return assign({}, state, {
    recommendedText,
    recommendedPlan,
    recommendedId,
    recommendedNetwork,
    plansMode,
    isSepareteRecommended,
    join,
    childrenRecommended,
    children,
    detailsJoin,
  });
};

const changeHighlightText = (state = INITIAL_STATE, { payload }) => {
  return assign({}, state, { highlightText: payload });
};

const changeNoteText = (state = INITIAL_STATE, { payload }) => {
  return assign({}, state, { noteText: payload });
};

const keysToUpdateLocalStorage = ["customPreferenceOne", "customPreferenceTwo", "report"];
const updateLocalStorageCustomPreferences = async (obj) => {
  const keys = Object.keys(obj);
  if (keys.length === 0) return;

  const reportId = keys.reduce((id, key) => {
    if (id) return id;
    return obj[key].reportId || obj[key]._id;
  }, null);

  let localValues = null;
  try {
    localValues = await loadData(reportId);
  } catch (error) {
    console.log(error);
  }

  if (localValues) {
    keys.forEach((key) => {
      localValues[key] = obj[key];
    });

    saveData(reportId, localValues);
  }
};

const switchState = (state = INITIAL_STATE, { payload }) => {
  const filteredPayload = {};
  Object.keys(payload).forEach((key) => {
    if (keysToUpdateLocalStorage.includes(key)) {
      filteredPayload[key] = payload[key];
    }
  });
  Object.keys(filteredPayload).length && updateLocalStorageCustomPreferences(filteredPayload);
  return assign({}, state, payload);
};

const loadRecommendedByReport = (state = INITIAL_STATE, { payload }) => {
  const {
    data,
    broaderNetworks,
    providersGrid,
    optimization,
    isDue,
    event,
    broaderNetworksFromAllMyOptions,
    plansFromPartner,
    plansFromBirthParent,
    preferenceBirthParentPlans,
    preferencePartnerPlans,
    indexToSplit,
    join,
  } = payload;
  let recommendedIndex = [];
  let howManyHospitals = "";
  let howManyDoctors = "";
  let howManyDrugs = "";
  let howManyHospitalsLength = 0;
  let howManyDoctorsLength = 0;
  let howManyDrugsLength = 0;
  let recommendedFor = ["", ""];
  let broaderNetworkJoin = {};
  let broaderNetworkPlans = {};
  const hasNotSeparate = !plansFromPartner.length;
  const recommendedNetwork = state.recommendedNetwork;
  const hasKid = isDue || event.general.children > 0;
  const arr = data?.data?.plansArr.map((plan) => {
    return broaderNetworks.findIndex((net) => net.name === plan.broaderNetwork);
  });
  if (!arr) return;
  recommendedIndex = arr;

  const acceptProviders = providersGrid.map((item) => {
    return { networks: item.networks.filter((i) => i.accept), type: item.type };
  });

  const broaderNetworkIncludes = [];

  broaderNetworksFromAllMyOptions.map((broaderNetwork) => {
    let birthParentPlansIds = [];
    let partnerPlansIds = [];
    const { broaderNetwork: name } = broaderNetwork;
    const birthParentBroaderNetwork = broaderNetwork.plansNames.slice(0, indexToSplit);
    const partnerBroaderNetwork = broaderNetwork.plansNames.slice(indexToSplit);

    birthParentBroaderNetwork.map((plan) => {
      plan.plans.map((item) => {
        birthParentPlansIds.push(item.externalId);
      });
    });
    partnerBroaderNetwork.map((plan) => {
      plan.plans.map((item) => {
        partnerPlansIds.push(item.externalId);
      });
    });

    birthParentPlansIds.every((r) => preferenceBirthParentPlans?.includes(r)) &&
      partnerPlansIds.every((r) => preferencePartnerPlans?.includes(r)) &&
      broaderNetworkIncludes.push(name);

    broaderNetworkPlans[name] = {
      birthParentPlans: [],
      partnerPlans: [],
    };

    broaderNetworkPlans[name]["birthParentPlans"] = birthParentPlansIds;
    broaderNetworkPlans[name]["partnerPlans"] = partnerPlansIds;
  });

  let doctorsList = [];
  let drugsList = [];
  let hospitalsList = [];
  acceptProviders.map((item) => {
    switch (item.type) {
      case "Hospital":
        hospitalsList.push(item.networks);
        break;
      case "Drug":
        drugsList.push(item.networks);
        break;
      case "Doctor":
        doctorsList.push(item.networks);
        break;
      default:
        break;
    }
  });

  drugsList.map((item) => {
    item.some((i) => {
      if (recommendedNetwork[0] === i.name || recommendedNetwork[1] === i.name) {
        howManyDrugsLength++;
        return true;
      }
    });
  });

  howManyDrugs =
    drugsList.length === 0
      ? "hide"
      : howManyDrugsLength === drugsList.length
      ? "All"
      : howManyDrugsLength === 0
      ? "None of your"
      : "Some";

  hospitalsList.map((item) => {
    item.some((i) => {
      if (recommendedNetwork[0] === i.name || recommendedNetwork[1] === i.name) {
        howManyHospitalsLength++;
        return true;
      }
    });
  });

  howManyHospitals =
    hospitalsList.length === 0
      ? "hide"
      : howManyHospitalsLength === hospitalsList.length
      ? "All"
      : howManyHospitalsLength === 0
      ? "None of your"
      : "Some";

  doctorsList.map((item) => {
    item.some((i) => {
      if (recommendedNetwork[0] === i.name || recommendedNetwork[1] === i.name) {
        howManyDoctorsLength++;
        return true;
      }
    });
  });
  howManyDoctors =
    doctorsList.length === 0
      ? "hide"
      : howManyDoctorsLength === doctorsList.length
      ? "All"
      : howManyDoctorsLength === 0
      ? "None of your"
      : "Some";

  const { heading, subHeading } = optimization;
  if (heading.includes("Separate")) {
    const birthParentFields = subHeading[0].split(":");
    const partnerFields = subHeading[1].split(":");
    recommendedFor = [birthParentFields[0], partnerFields[0]];
  }

  return assign({}, state, {
    recommendedIndex,
    howManyHospitals,
    howManyDoctors,
    howManyDrugs,
    recommendedFor,
    recommendedNetwork,
    hasKid,
    hasNotSeparate,
    broaderNetworkIncludes,
    broaderNetworkIncludesOnLoad: broaderNetworkIncludes,
    broaderNetworkJoin,
    broaderNetworkPlans,
    plansToJoin: join,
  });
};

const resetState = (state = INITIAL_STATE, {}) => {
  return assign({}, state, INITIAL_STATE);
};

export const HANDLERS = {
  [Types.LOAD_RECOMMENDED_BY_OPTMIZATION]: loadRecommendedByOptmization,
  [Types.LOAD_RECOMMENDED_BY_REPORT]: loadRecommendedByReport,
  [Types.CHANGE_HIGHLIGHT_TEXT]: changeHighlightText,
  [Types.CHANGE_NOTE_TEXT]: changeNoteText,
  [Types.SWITCH_STATE]: switchState,
  [Types.RESET_STATE]: resetState,
};

const reducer = createReducer(INITIAL_STATE, HANDLERS);

export default reducer;
