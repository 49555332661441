const dbName = "cachedReportsDB";
const storeName = "cachedReportsStore";

const openDB = () => {
  return new Promise((resolve, reject) => {
    const request = indexedDB.open(dbName, 1);

    request.onupgradeneeded = (event) => {
      const db = event.target.result;

      if (!db.objectStoreNames.contains(storeName)) {
        db.createObjectStore(storeName, { keyPath: "id" });
      }
    };

    request.onsuccess = (event) => resolve(event.target.result);
    request.onerror = (event) => reject(event.target.error);
  });
};

export const saveData = async (id, data) => {
  const db = await openDB();

  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);

  store.put({ id, value: data });
};

export const loadData = async (id) => {
  const db = await openDB();

  return new Promise((resolve, reject) => {
    const transaction = db.transaction(storeName, "readonly");
    const store = transaction.objectStore(storeName);

    const request = store.get(id);

    request.onsuccess = () => {
      if (request.result) {
        resolve(request.result.value);
      } else {
        reject(new Error("No data found"));
      }
    };

    request.onerror = (event) => reject(event.target.error);
  });
};

export const deleteData = async (id) => {
  const db = await openDB();

  const transaction = db.transaction(storeName, "readwrite");
  const store = transaction.objectStore(storeName);

  store.delete(id);
};
