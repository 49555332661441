import React from "react";
import styled from "styled-components";
import {
  CalculateFormTemplate,
  Text,
  GrandTotal,
  CalculatePriceForm,
  Link,
  ModalView,
  Currency,
  Spinner,
} from "components";

import useModal from "services/hooks/useModal.hook";

const Wrapper = styled.div`
  margin-top: 15px;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: stretch;
  position: relative;
  min-height: 600px;
  height: fit-content;

  @media (max-width: 600px) {
    height: auto;
  }
`;

const InnerWrapper = styled.div`
  min-height: 56px;
  line-height: 1.1em;
`;

const Footer = styled.footer`
  text-align: center;
`;

const FooterDiv = styled.div`
  padding: 20px;
  align-content: center;
  height: 100%;
  border-top: 4px solid rgba(220, 76, 45);
  border-radius: 0 0 25px 25px;
  background: rgba(253, 246, 244);
`;

const Main = styled.div`
  padding: 0 5px;
  min-height: 380px;
  display: flex;
  align-items: center;
`;

const FormText = styled(Text)`
  padding: 0 15px;
`;

const StyledText = styled(Text)`
  font-size: 16px;
  line-height: 25px;
`;

const AdviceButton = styled.div`
  /* width: 90%; */
  margin: 0 auto 20px;
  justify-content: center;
  background-color: #1c355e;
  border-radius: 10px;
  padding: 8px 10px;
`;

const ModalContentView = styled.div`
  padding: 20px 45px;
  max-width: 700px;
`;

const PredictedTotal = (props) => {
  const { handleClick, isShow } = useModal();

  const renderFirst = () => (
    <FormText circular arial>
      {props.error ||
        props.isDoctorInNetworkPrev !== null ||
        `Tell us about your birth plan and insurance plan details to the left,
        and we'll calculate your deductibles and out-of-pocket costs here`}
    </FormText>
  );

  const renderData = () => <CalculatePriceForm {...props} newStyle />;

  const FooterComponent = (props) => {
    return (
      <Footer>
        {props.charges.length > 0 && (
          <FooterDiv>
            <>
              <GrandTotal total={props.grandTotal} title="Estimated Grand Total" newStyle />
              <InnerWrapper>
                {props.basedOnBillCharges && (
                  <>
                    <StyledText circular bold color="#1c355e">
                      {"This is based on bills of "}
                      <Currency price={props.basedOnBillCharges} /> {"from real pregnancies featured below"}
                    </StyledText>
                    <Link circular bold underline small blue onClick={handleClick}>
                      {`(What's not included?)`}
                    </Link>
                  </>
                )}
              </InnerWrapper>
              <br></br>
              <AdviceButton>
                <Link circular href="/groups" button fontSize={16} bold>
                  {"Get Advice on Choosing a Plan"}
                </Link>
              </AdviceButton>
            </>
          </FooterDiv>
        )}
      </Footer>
    );
  };

  return (
    <>
      <ModalView close={handleClick} isShow={isShow} isInfo title="What's not included?">
        <ModalContentView>
          <Text circular arial modal>
            This does not subtract any money that you or your employer have added to your Health Savings Account (HSA)
            by the time you receive your bills. Additionally, this estimate does not include the cost of non-routine
            prenatal tests your doctor may suggest, such as genetic testing or additional ultrasounds above what the
            Affordable Care Act (ACA) defines as "routine." Under the ACA, insurance plans must cover routine prenatal
            tests, so depending on your insurance, these tests may be covered 100% by insurance. You can check with your
            insurance company to see which prenatal tests are considered routine under your plan.
          </Text>
        </ModalContentView>
      </ModalView>
      <CalculateFormTemplate
        noBorder
        borderShadow
        headerHeight={"50px"}
        title={`Your Predicted Out-of-Pocket Costs`} //this hyfen \u2011 don't break with the line
        footer={() => FooterComponent(props)}
      >
        <Wrapper big={!props.charges}>
          <Main>{props.isRequesting ? <Spinner center /> : props.charges.length ? renderData() : renderFirst()}</Main>
        </Wrapper>
      </CalculateFormTemplate>
    </>
  );
};

export default PredictedTotal;
