import { InputNumber } from "antd";
import { Text } from "components";
import { formatterRate, parserRate } from "utils/rate";
import { formatterNumber, parserNumber } from "utils/currency";
import React from "react";
import { useDispatch } from "react-redux";
import Actions from "store/report/actions";
import { CheckOutlined } from "@ant-design/icons";
import CustomSelect from "components/atoms/CustomSelect";
import { ItensWrapper, ItensWrapperHeading, CustomRadioCheckGroup, USDollar } from "..";
import { RadioGroup } from "../components/RadioGroup";

export const HSABenefitFilter = ({
  report,
  hsaHraImpact,
  hsaContribution,
  hsaContributionOtherValue,
  hsaBalanceInvest,
  hsaBalanceReturn,
  hsaBalanceTimePeriod,
  taxBracket,
  handleOtherValueChange,
  handleHsaBalanceReturn,
  handleHsaPeriod,
}) => {
  const reportYear = new Date(report.dates[0]).getFullYear();
  const IRSMaxValuesByYear = {
    2024: {
      individual: 4150,
      family: 8300,
    },
    2025: {
      individual: 4300,
      family: 8550,
    },
  };
  const maxIRSLimits = IRSMaxValuesByYear[reportYear] || IRSMaxValuesByYear[2024];

  const dispatch = useDispatch();

  return (
    <>
      <RadioGroup
        name="radio-hsa-benefit"
        label="Tax and Investment Assumptions"
        options={[
          {
            value: "spending-only",
            label: "Healthcare Spending Only",
            description: "This only includes visits and doesn’t assume any investing activities.",
          },
          {
            value: "spending-hsa-hra",
            label: "Healthcare Spending with HSA Benefit",
            description:
              "This includes all of your visits as well as the triple-tax benefits of contributing to and investing your Health Savings Account (HSA).",
          },
        ]}
        onChange={(value) => {
          dispatch(
            Actions.Creators.switchState({
              hsaHraImpact: value,
            }),
          );
        }}
        value={hsaHraImpact}
        rightAligned
      />

      {hsaHraImpact === "spending-hsa-hra" && (
        <>
          <ItensWrapper>
            <RadioGroup
              name="radio-hsa-contribution"
              label="HSA Contribution"
              options={[
                { value: "nothing", label: "Nothing" },
                {
                  value: "max",
                  label: `Maximum (${
                    report.type === "individual"
                      ? USDollar.format(maxIRSLimits.individual)
                      : USDollar.format(maxIRSLimits.family)
                  } less employer contribution)`,
                },
              ]}
              onChange={(value) => {
                dispatch(
                  Actions.Creators.switchState({
                    hsaContribution: value,
                  }),
                );
              }}
              value={hsaContribution}
            />

            {hsaContribution === "other" && (
              <InputNumber
                style={{
                  background: "inherit",
                  backgroundColor: "inherit",
                  border: "2px solid #D0D5DD",
                  borderRadius: "5px",
                  marginTop: "3px",
                  marginBottom: "7px",
                  width: "100%",
                  color: "#344054",
                  padding: "8px",
                }}
                value={hsaContributionOtherValue}
                onChange={handleOtherValueChange}
                formatter={formatterNumber}
                parser={parserNumber}
                min={0}
                max={report.type === "individual" ? 4150 : 8300}
              />
            )}
          </ItensWrapper>

          <ItensWrapper>
            <ItensWrapperHeading>Invest HSA</ItensWrapperHeading>
            <CustomRadioCheckGroup>
              <li className="group-no-border">
                <label>
                  <div className={`check-input-radio-wrapper ${hsaBalanceInvest ? "checked" : ""}`}>
                    <input
                      type="radio"
                      name="radio-invest-hsa"
                      value={true}
                      checked={hsaBalanceInvest}
                      onChange={(e) => {
                        dispatch(
                          Actions.Creators.switchState({
                            hsaBalanceInvest: e.target.checked,
                          }),
                        );
                      }}
                    />
                    <div className="check-icon-radio">
                      <CheckOutlined />
                    </div>
                  </div>
                  <span className="radio-plans-label">
                    {`Yes, with this amount of tax-free ${hsaBalanceReturn >= 0 ? "gain" : "loss"}:`}
                  </span>
                </label>
              </li>

              <div style={{ paddingLeft: "30px", display: "grid", gap: "22px" }}>
                <CustomSelect
                  value={hsaBalanceReturn}
                  values={[
                    { value: -20, label: "-20%" },
                    { value: -15, label: "-15%" },
                    { value: -10, label: "-10%" },
                    { value: -5, label: "-5%" },
                    { value: 0, label: "0%" },
                    { value: 5, label: "5%" },
                    { value: 10, label: "10%" },
                    { value: 15, label: "15%" },
                    { value: 20, label: "20%" },
                  ]}
                  onChange={handleHsaBalanceReturn}
                  zIndex={3}
                />

                <label>
                  <span
                    style={{
                      color: "#21355b",
                      fontWeight: 500,
                    }}
                  >
                    Compounded for this many years:
                  </span>
                </label>
                <CustomSelect
                  label="Compounded for this many years:"
                  value={hsaBalanceTimePeriod}
                  values={[
                    { value: 1, label: "1" },
                    { value: 2, label: "2" },
                    { value: 5, label: "5" },
                    { value: 10, label: "10" },
                    { value: 20, label: "20" },
                  ]}
                  onChange={handleHsaPeriod}
                  zIndex={2}
                />
              </div>

              <li className="group-no-border">
                <label>
                  <div className={`check-input-radio-wrapper ${!hsaBalanceInvest ? "checked" : ""}`}>
                    <input
                      type="radio"
                      name="radio-invest-hsa"
                      value={false}
                      checked={!hsaBalanceInvest}
                      onChange={(e) => {
                        dispatch(
                          Actions.Creators.switchState({
                            hsaBalanceInvest: !e.target.checked,
                          }),
                        );
                      }}
                    />
                    <div className="check-icon-radio">
                      <CheckOutlined />
                    </div>
                  </div>
                  <span className="radio-plans-label">No</span>
                </label>
              </li>
            </CustomRadioCheckGroup>
          </ItensWrapper>

          <ItensWrapper>
            <ItensWrapperHeading>Your Tax Bracket</ItensWrapperHeading>
            <CustomSelect
              label="Your Tax Bracket"
              value={taxBracket}
              values={[
                { value: 10, label: "10%" },
                { value: 12, label: "12%" },
                { value: 22, label: "22%" },
                { value: 24, label: "24%" },
                { value: 32, label: "32%" },
                { value: 35, label: "35%" },
                { value: 37, label: "37%" },
              ]}
              onChange={(value) => {
                dispatch(
                  Actions.Creators.switchState({
                    taxBracket: value,
                  }),
                );
              }}
              zIndex={1}
            />
          </ItensWrapper>
        </>
      )}
    </>
  );
};
